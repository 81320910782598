import { useEffect, useState } from "react";

function CountdownTimer({textBlack}) {
  const [expiryTime, setExpiryTime] = useState("10 mar 2025 16:26:00");
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownlMinutes: "",
    countdownSeconds: "",
  });
  const countdownTimer = () => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(expiryTime).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(timeInterval);
        setExpiryTime(false);
      }
    }, 1000);
  };

  useEffect(() => {
    countdownTimer();
  },[]);

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className={`btn-group my-0 flex items-center	 ${textBlack? "my-1": ""}`}>
          {expiryTime !== false ? (
            <>
              <div type="button" className={`btn btn-outline-success flex flex-col 
              text-center items-center text-white px-2 border-r-[1px] text-xl h-[20px] 
              ${textBlack? "salle bg-[#0066ff] w-[42px] h-[42px] rounded-full text-[20px]": ""}`}>
                <p className={` ${textBlack? "mt-[3px] text-white my-2 mt-0": "mt-[-10px]"}`}>{countdownTime.countdownDays}</p>
                 <p className={`mt-[-13px] text-[14px] ${textBlack? "text-white mt-[-20px] text-[10px]": ""}`}>დღე</p>
              </div>

              <div type="button" className={`btn btn-outline-success flex flex-col
               text-center items-center text-white px-2  border-r-[1px] text-xl h-[20px]
                ${textBlack? "salle bg-[#0066ff] w-[42px] h-[42px] mx-[2px] rounded-full text-[20px]": ""}`}>
                <p className={` ${textBlack? "mt-[3px] text-white my-2 mt-0": "mt-[-10px]"}`}>{countdownTime.countdownHours}</p>
                 <p className={`mt-[-13px] text-[14px] ${textBlack? "text-white mt-[-20px] text-[10px]": ""}`}>საათი</p>
              </div>

              <div type="button" className={`btn btn-outline-success flex flex-col 
              text-center items-center text-white px-2  border-r-[1px] text-xl h-[20px]
               ${textBlack? "salle bg-[#0066ff] w-[42px] h-[42px] rounded-full mr-[2px] text-[20px]": ""}`}>
                <p className={`${textBlack? "mt-[3px] text-white my-2 mt-0": "mt-[-10px] "}`}>{countdownTime.countdownMinutes}</p>
                 <p className={`mt-[-13px] text-[14px] ${textBlack? "text-white mt-[-20px] text-[10px]": ""}`}>წუთი</p>
              </div>

              <div type="button" className={`btn btn-outline-success flex flex-col 
              text-center items-center text-white px-2 text-xl h-[20px] ${textBlack? 
              "salle bg-[#0066ff] w-[42px] h-[42px] rounded-full text-[20px]": ""}`}>
                <p className={` ${textBlack? "mt-[3px] text-white my-2 mt-0": "mt-[-10px]"}`}>{countdownTime.countdownSeconds}</p>
                 <p className={`mt-[-13px] text-[14px] ${textBlack? "text-white mt-[-20px] text-[10px]": ""}`}>წამი</p>
              </div>
            </>
          ) : (
            <p className="text-white">აქცია დასრულდა</p>
          )}
        </div>
      </div>
    </div>
  );
}
export default CountdownTimer;
