import ProdSrch from "./wholeProdSrch";
import {PaginPart} from "./pagination";
import {useState} from 'react';

function WholeprodContent() {
  const [filterFlag,setFilterFlag] = useState(false);
  const handleFilterFlag = ()=>{
    setFilterFlag(!filterFlag)
  }
  return (
    <div className="out">
      <div className="content-of-wholepage margin-bottom-primary">
        <ProdSrch handleFilterFlag={handleFilterFlag} filterFlag={filterFlag}/>
        <PaginPart handleFilterFlag={handleFilterFlag}/>
      </div>
    </div>
  );
}

export default WholeprodContent;
