import { X } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ScreenResolution from "../utills/screenResolution";
import { useState, useEffect } from "react";

function ListItem({ link, name, handleShow }) {
  return (
    <li
      onClick={() => {
        handleShow();
      }}
    >
      <Link to={link}>{name}</Link>
    </li>
  );
}

function MegaMenuPage({ handleShow,thisShow }) {
  const [show, setShow] =  useState(false);
  const [resolution, setResolution] = useState(0);

  const handleResolution = (value) => {
    setResolution(value);
  };

  useEffect(()=>{
    setShow(prev=>thisShow)
  },[thisShow])

  return (
    <div
      className={`mega-menu-content ${
        resolution <= 1000
          ? show
            ? "slide-show"
            : "slide-hidden"
          : ""
      }`}
    >
      <ScreenResolution setResolution={handleResolution} />
      <div
        onClick={() => {
          handleShow();
        }}
        className="main-menu-list-icon-exit"
      >
        <X className="i" />
      </div>
      <div className="row left-cont">
        <div className="text">
          <h1 className="title">ტანსაცმელი</h1>
          <ul className="mega-list">
            {menuData.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  name={item.name}
                  link={item.link}
                  handleShow={handleShow}
                />
              );
            })}
          </ul>
        </div>
      </div>
      <div className="row mid-cont">
        <div className="text">
          <h1 className="title">ჩანთები და აქსესუარები</h1>
          <ul className="mega-list">
            <li className="">ჩანთები და აქსესუარები</li>
            <li className="">ჩანთები და ზურგჩანთები</li>
            <li className="">საფულეები</li>
            <li className="">მზის სათვალეები</li>
            <li className="">სათვალეების აქსესუარები</li>
            <li className="">ქამრები</li>
            <li className="">ქოლგები</li>
            <li className="">ჰალსტუხები, ბაბთები და პაჟები</li>
          </ul>
        </div>
      </div>
      <div className="row right-cont">
        <div className="text">
          <h1 className="title">ქვაბებისა და ტაფების ნაკრები</h1>
          <ul className=" up mega-list">
            <li className="">გრანიტის ნაკრები</li>
            <li className="">უჟანგავი ფოლადის ნაკრები</li>
            <li className="">ემალის ნაკრები</li>
            <li className="">სრული კატალოგი</li>
          </ul>
          <h1 className="title">ჩანთები და აქსესუარები</h1>
          <ul className="down mega-list">
            <li className="">სტანდარტული თეფში</li>
            <li className="">სუპის თეფში</li>
            <li className="">სასალათე თეფში</li>
            <li className="">ტკბილეულის თეფში</li>
            <li className="">პიცის თეფში</li>
            <li className="">ჯამი</li>
            <li className="">სრული კატალოგი</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const menuData = [
  {
    id: 1,
    name: "კონტაქტი",
    link: "../contact-page",
  },
  {
    id: 2,
    name: "რეგისტრაცია",
    link: "../aut-reg",
  },
  {
    id: 3,
    name: "პროდუქტის გვერდი",
    link: "../whole-products-page",
  },
  {
    id: 4,
    name: "დაგავიწყდატ პაროლი",
    link: "../forget-password",
  },
  {
    id: 5,
    name: "ჩვენს შესახებ",
    link: "../about",
  },
  {
    id: 6,
    name: "ინფორმაცია",
    link: "../my-information",
  },
  {
    id: 7,
    name: "წარმატებით",
    link: "../success",
  },

  {
    id: 8,
    name: "ბლოგები",
    link: "../blogs",
  },
  {
    id: 9,
    name: "ხშირი კითხვები",
    link: "../faq",
  },
  {
    id: 10,
    name: "ინვოისი",
    link: "../invoice",
  },
  {
    id: 11,
    name: "პროფაილი",
    link: "../profile",
  },
];

export default MegaMenuPage;
