import SimpleSlider from "./slider";
import ProductCategori from "./catsProduct";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function Foniani() {
  return (
    <div className="out foni">
      <div className="gray-part margin-top-primary">
        <div className="top-slider margin-bottom-primary">
          <SimpleSlider />
        </div>
        <div className="object-categories-container margin-bottom-primary scrollbar">
          <div className="object-categories">
            <ProductCategori/>
            <ProductCategori/>
            <ProductCategori/>
            <ProductCategori/>
            <ProductCategori/>
            <div className=" h-[190px] flex items-center justify-center">
              <Link to={"whole-products-page"}>
                <div className="rounded-full bg-[#0066ff] hover:bg-[#609efb] w-[60px] h-[60px] flex items-center justify-center">
                  <ArrowRight className="text-white text-2xl font-bold" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Foniani;
