import {
  GeoAlt,
  TelephoneFill,
  Envelope,
  Facebook,
  Twitter,
  Youtube,
} from "react-bootstrap-icons";

function ContactContent() {
  return (
    <div className="out">
      <div className="contact-down-part margin-bottom-primary">
        <div className="send-message">
          <h1 className="main-title">შეტყობინების გაგზავნა</h1>
          <div className="border">
            <div className="left"></div>
          </div>
          <div className="name-surname">
            <div className="name-part">
              <div className="name">
                <p>სახელი</p>
                <p className="fifq">*</p>
              </div>
              <input type="text" className="inputi-name"></input>
            </div>
            <div className="surname-part">
              <div className="surname">
                <p>გვარი</p>
                <p className="fifq">*</p>
              </div>
              <input type="text" className="inputi-surname"></input>
            </div>
          </div>
          <div className="e-mail-part">
            <div className="e-mail">
              <p>ელ.ფოსტა</p>
              <p className="fifq">*</p>
            </div>
            <input type="text" className="inputi-email"></input>
          </div>
          <div className="title-part">
            <div className="title">
              <p>სათაური</p>
              <p className="fifq">*</p>
            </div>
            <input type="text" className="inputi-title"></input>
          </div>
          <textarea
            placeholder="შეტყობინება..."
            className="textarea-part"
          ></textarea>
          <h4 className="send-button">გაგზავნა</h4>
        </div>
        <div className="follow">
          <div className="up-follow">
            <h1 className="title">გამოგვყევით</h1>
            <div className="border">
              <div className="left"></div>
            </div>
            <div className="contacts-info">
              <div className="adres">
                <div className="icn">
                  <GeoAlt className="i" />
                </div>
                <div className="adres-text">
                  <p className="adres-title">მისამართი</p>
                  <p className="adresi">საქართველო, თბილისი</p>
                </div>
              </div>
              <div className="mob-el">
                <div className="mobile">
                  <div className="icn">
                    <TelephoneFill className="i" />
                  </div>
                  <div className="mobile-text">
                    <p className="mobile-title">მობილური</p>
                    <p className="adresi">(+995) 571 777 777</p>
                  </div>
                </div>
                <div className="e-mail">
                  <div className="icn">
                    <Envelope className="i" />
                  </div>
                  <div className="e-mail-text">
                    <p className="e-mail-title">ელ.ფოსტა</p>
                    <p className="adresi">artspace@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="down-follow">
            <h1 className="title">შემოგვიერთდით</h1>
            <div className="border">
              <div className="left"></div>
            </div>
            <div className="icons">
              <div className="f bg-[#3b5998] hover:bg-[#5d78b0] cursor-pointer	">
                <a href="#">
                  <Facebook className="i" />
                </a>
              </div>
              <div className="t bg-[#00acee] hover:bg-[#62d2fd] cursor-pointer	">
                <a href="#">
                  <Twitter className="i" />
                </a>
              </div>
              <div className="y bg-[#ff0000] hover:bg-[#ff6060] cursor-pointer	">
                <a href="#">
                  <Youtube className="i" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactContent;
