import React from "react";
import { useState, useEffect } from "react";

function ProgBar({ progress, height, totalAmount, leftAmount }) {
  const [prg, setPrg] = useState(0);

  useEffect(() => {
    var calc = (leftAmount / totalAmount) * 100;
    setPrg(calc);
  }, [totalAmount, leftAmount]);

  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "#f2f3f8",
    borderRadius: 40,
    marginTop: 14,
  };

  const Childdiv = {
    height: "100%",
    width: `${prg}%`,
    backgroundColor: "#0066ff",
    borderRadius: 40,
    textAlign: "right",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}></div>
    </div>
  );
}

export default ProgBar;
