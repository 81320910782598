import frame1img from "../../../imgs/Frame 1.png";
import frame2img from "../../../imgs/Frame 2.png";

function TwoAdvert() {
  return (
    <div className="out">
      <div className="advert-part margin-bottom-primary">
        <div className="up-addvert">
          <div className="left">
            <img src={frame1img} alt=""></img>
          </div>
          <div className="right">
            <img src={frame2img} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwoAdvert;
