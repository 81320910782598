
import {ArrowRight} from "react-bootstrap-icons";
function PagePath({
  path = ['მთავარი','მობილურ','Apple','Apple iPhone 14']
}
  ) {
  return (
    <div className="out foni">
      <section className="path-style-one items-center gap-5">
        {path.map((item, index) => {
          return (
            <div key={index} className="flex items-center gap-2">
              <ArrowRight className="text-gray-500 font-bold text-[18px] max-sm:text-[16px]" />
              <p className="text-gray-500 font-bold text-[18px] max-sm:text-[16px]" >{item}</p>
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default PagePath
