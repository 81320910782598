import ForgetContent from "../content/forgetContent/forgetContent";

function ForgetPassword() {
  return (
    <>
      <ForgetContent />
    </>
  );
}

export default ForgetPassword;
