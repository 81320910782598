import LastBigAdvertPart from "../content/mainpage/lastBigAdvert";
import OneLineAdvertsPart from "../content/mainpage/oneLineAdverts";
import SecOneLineAdvertsPart from "../content/mainpage/secondonelineadv";
import TwoAdvert from "../content/mainpage/twoAdvert";
import SpecialOrders from "../content/mainpage/specialOrdersCards";
import Foniani from "../content/mainpage/uppFoni";
import Advslider from "../content/mainpage/AdvertSlider";
import SalePart from "../content/mainpage/sale";
import SliderTimer from "../content/mainpage/sliderWithTimer";

function MainPage() {
  return (
    <>
      <Foniani />
      <SalePart/>
      <SpecialOrders />
      <TwoAdvert />
      <SliderTimer/>
      <OneLineAdvertsPart />
      <SecOneLineAdvertsPart />
      <LastBigAdvertPart />
      <Advslider />
    </>
  );
}

export default MainPage;
