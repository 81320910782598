import PagePath from "../pathOfPages";
import ProfileContent from "../content/profile/profile";


function ProfilePage(){
    return(
        <>
        <PagePath/> 
        <ProfileContent/>
        </>
    )
}

export default ProfilePage