import MultiRangeSlider from "./doubleRangeSlider";
import { useState, useEffect } from "react";
import { CaretDownFill, CaretUpFill, Search, X } from "react-bootstrap-icons";
import ScreenResolution from "../../utills/screenResolution";

const ProductCategory = ({ categoryData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <h2 className="text-xl">კატეგორიები</h2>
      <div className="py-3">
        {categoryData.map((item, index) => {
          return (
            <div key={index} className="py-1">
              {!(item.subCategoryNames.length === 0) ? (
                <div>
                  <div
                    onClick={handleOpen}
                    className="flex items-center justify-between"
                  >
                    <div className="flex items-center gap-2">
                      <input type="radio" />
                      <p>{item.categoryName}</p>
                    </div>
                    {isOpen ? <CaretUpFill /> : <CaretDownFill />}
                  </div>
                  {isOpen ? (
                    <div>
                      {item.subCategoryNames.map((subItem, index) => {
                        return (
                          <div
                            className="flex items-center gap-2 pl-4 py-1"
                            key={index}
                          >
                            <input type="radio" />
                            <p>{subItem}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div>
                  <div className="flex items-center gap-2">
                    <input type="radio" />
                    <p>{item.categoryName}</p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const categoryData = [
  {
    categoryName: "კომპიუტერის აქსესუარები",
    subCategoryNames: ["სკამი", "კლავიატურა"],
  },
  {
    categoryName: "მონიტორები",
    subCategoryNames: [],
  },
  {
    categoryName: "ტელევიზორები",
    subCategoryNames: [],
  },
  {
    categoryName: "ნოუყთბუქები",
    subCategoryNames: [],
  },
  {
    categoryName: "სარეცხი მანქანები",
    subCategoryNames: [],
  },
];

function ProdSrch({ handleFilterFlag, filterFlag }) {
  const [srchFilterFlag, setSrchFilterFlag] = useState(filterFlag);
  const [resolution, setResolution] = useState(0);

  const handleResolution = (value) => {
    setResolution(value);
  }

  useEffect(() => {
    setSrchFilterFlag(filterFlag);
  }, [filterFlag]);

  return (
    <div
      className={`prod-srch-part 
      ${resolution <= 1000 ? "scrollbar product-filter-hidden" : ""}
      ${
        resolution <= 1000
          ? srchFilterFlag
            ? "slide-show"
            : "slide-hidden"
          : ""
      }`}
    >
      <ScreenResolution setResolution={handleResolution}/>
      <div className="product-fitler-title">
        <h1 className="maintitle">ფილტრი</h1>
        <div
          onClick={() => {
            handleFilterFlag();
          }}
          className="product-fitler-exit"
        >
          <X className="product-fitler-exit-i" />
        </div>
      </div>
      <div className="myborder">
        <div className="oneborder"></div>
      </div>

      <div className="my-4 flex items-center">
        <input
          type="search"
          className="border border-r-0 focus:outline-none px-2 h-[40px] w-[82%] rounded-tl-lg rounded-bl-lg"
          placeholder="პროდუქტის ძებნა"
        />
        <Search className="h-[40px] w-[18%] p-2 text-white bg-[#0066ff] rounded-tr-lg rounded-br-lg cursor-pointer hover:bg-[#10489c]" />
      </div>

      <ProductCategory categoryData={categoryData} />

      <div className="price">
        <p className="title">ფასი</p>
        <MultiRangeSlider
          min={0}
          max={1000}
          onChange={({ min, max }) => console.log()}
        />
      </div>
      <div className="brands">
        <p className="brand-title">ბრენდი</p>
        <div>
          <input type="checkbox" name="a"></input>
          <label htmlFor="a">apple</label>
        </div>
        <div>
          <input type="checkbox" name="b"></input>
          <label htmlFor="b">Xiaomi</label>
        </div>
        <div>
          <input type="checkbox" name="c"></input>
          <label htmlFor="c">Samsung</label>
        </div>
        <div>
          <input type="checkbox" name="d"></input>
          <label htmlFor="d">Huawei</label>
        </div>
        <div>
          <input type="checkbox" name="e"></input>
          <label htmlFor="e">Google</label>
        </div>
        <div>
          <input type="checkbox" name="f"></input>
          <label htmlFor="f">Nokia</label>
        </div>
      </div>
      <div className="date">
        <p className="date-title">გამოშვების წელი</p>
        <div>
          <input type="checkbox" name="aa"></input>
          <label htmlFor="aa">2022</label>
        </div>
        <div>
          <input type="checkbox" name="bb"></input>
          <label htmlFor="bb">2021</label>
        </div>
        <div>
          <input type="checkbox" name="cc"></input>
          <label htmlFor="cc">2020</label>
        </div>
      </div>
      <div className="sim-type">
        <p className="sim-type-title">სიმ ბარათი-SIM</p>
        <div>
          <input type="checkbox" name="aaa"></input>
          <label htmlFor="aaa">Dual SIM</label>
        </div>
        <div>
          <input type="checkbox" name="bbb"></input>
          <label htmlFor="bbb">Hybrid Dual SIM</label>
        </div>
      </div>
      <div className="camera-type">
        <p className="camera-type-title">მთავარი კამერის ტიპი</p>
        <div>
          <input type="checkbox" name="aaaa"></input>
          <label htmlFor="aaaa">Dual</label>
        </div>
        <div>
          <input type="checkbox" name="bbbb"></input>
          <label htmlFor="bbbb">Five</label>
        </div>
        <div>
          <input type="checkbox" name="cccc"></input>
          <label htmlFor="cccc">Four</label>
        </div>
      </div>
      <h2 className="clear-btn">გასუფთავება</h2>
    </div>
  );
}

export default ProdSrch;
