import AdvertSlider from "./sldr";

function ProdCategs() {
  return (
    <div className="out">
      <div className="ProdCat max-w-[1500px] w-[100%] px-[100px]">
      <div className="flex overflow-x-scroll margin-bottom-primary pb-[15px] scrollbar">
          <AdvertSlider className="px-[110px]"/>
      </div>
      </div>
    </div>
  );
}

export default ProdCategs;
