import CountdownTimer from "../../countdowntimer";

function SlidTimer() {
    return (
      <div className="timer-sl">
        <CountdownTimer/>
      </div>
    );
  }
  
  export default SlidTimer;
  