import PagePath from "../pathOfPages";
import AvtoRegCont from "../content/autoregpage/Avtorreg";


function AutoReg(){
    return(
        <>
        <PagePath/> 
        <AvtoRegCont/>
        </>
    )
}

export default AutoReg