import BlogsCard from "./blogsCard";
import cardBg from "../../../imgs/sky.jpg";
import { useParams } from "react-router-dom";

const BlogsContentLayout = () => {
  const {blogsId} = useParams();
  return (
    <div className="out">
      <div className="flex justify-between flex-wrap gap-y-10 max-w-[1500px] 
      max-lg:px-[45px] max-sm:px-[20px] px-[110px] margin-bottom-primary margin-top-primary">
        {
          data.map((item,index)=>{
            return(
              <BlogsCard key={item.id} id={item.id}  img={item.img} blogTitle={item.name}/>
            )
          })
        }
      </div>
    </div>
  );
};

export default BlogsContentLayout;

const data = [
  {
    id: 1,
    img: cardBg,
    name: "ბლოგის სახელი"
  },
  {
    id: 2,
    img: cardBg,
    name: "ბლოგის სახელი ორი"
  },
  {
    id: 3,
    img: cardBg,
    name: "ბლოგის სახელი სამი"
  },
  {
    id: 4,
    img: cardBg,
    name: "ბლოგის სახელი ოთხი"
  },
  {
    id: 5,
    img: cardBg,
    name: "ბლოგის სახელი ხუთი"
  },
  {
    id: 6,
    img: cardBg,
    name: "ბლოგის სახელი ექვსი"
  },
  {
    id: 7,
    img: cardBg,
    name: "ბლოგის სახელი"
  },
  {
    id: 8,
    img: cardBg,
    name: "ბლოგის სახელი"
  }
]