import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import { ChevronCompactLeft, ChevronCompactRight } from "react-bootstrap-icons";
import React, { useRef, useState, useEffect, useContext } from "react";
import { ProductDataContext } from "../../../App";
import MiniDescription from "../miniDescription/miniDescription.";

function SliderComponentTwo({ SliderSlideComponent }) {
  const contextData = useContext(ProductDataContext);
  const [productData, setProductata] = useState(contextData.data);
  const [slideToDisplay, setSlidesToDisplay] = useState(0);
  const [showMiniDesk, setShowMiniDesk] = useState(false);
  const [miniDescriptionId, setMiniDescriptionId] = useState(null);

  let swiperRef = useRef();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      console.log(windowSize[0]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleShowMiniDesk = () => {
    setShowMiniDesk((prev) => !prev);
  };

  const handleMiniDescriptionId = (id) => {
    setMiniDescriptionId((prev) => id);
  };

  useEffect(() => {
    if (windowSize[0] >= 1400) {
      setSlidesToDisplay(5);
    } else if (windowSize[0] >= 1200 && windowSize[0] <= 1400) {
      setSlidesToDisplay(4);
    } else if (windowSize[0] <= 1200 && windowSize[0] >= 800) {
      setSlidesToDisplay(3);
    } else if (windowSize[0] <= 800 && windowSize[0] >= 600) {
      setSlidesToDisplay(2);
    } else {
      setSlidesToDisplay(1);
    }
  }, [windowSize]);

  return (
    <div className="sale-sldr">
      <button
        className="cards-slid-left"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <ChevronCompactLeft className="lf" />
      </button>

      <button
        className="cards-slid-right"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <ChevronCompactRight className="rg" />
      </button>
      <Swiper
        modules={[Autoplay, EffectFade]}
        slidesPerView={slideToDisplay}
        pagination={{ clickable: false }}
        dots={"false"}
        loop={true}
        className="sale-prod"
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        disabledclass={"disabled_swiper_button"}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        speed={2000}
        cssease={"linear"}
      >
        {productData.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <SliderSlideComponent
                id={item.id}
                imgUrl={item.imgUrl}
                name={item.name}
                price={item.price}
                discountPrice={item.discountPrice}
                handleShowMiniDesk={handleShowMiniDesk}
                handleMiniDescriptionId={handleMiniDescriptionId}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {showMiniDesk && (
        <MiniDescription
          miniDescriptionId={miniDescriptionId}
          handleShowMiniDesk={handleShowMiniDesk}
        />
      )}
    </div>
  );
}

export default SliderComponentTwo;
