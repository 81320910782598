import PagePath from "../pathOfPages";
import ProdUpperInfo from '../content/productpage/prodFirstInfo';
import ProdBigInformation from '../content/productpage/prodBigInfo';
import ProdSlider from '../content/productpage/inProdBottomSlider';

function ProductPage() {
  return (
    <>
      <PagePath/> 
      <ProdUpperInfo/>
      <ProdBigInformation/>
      <ProdSlider myDelay={1500}/>
      <ProdSlider/>
    </>
  );
}

export default ProductPage;