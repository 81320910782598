import {
  ChevronDown,
  HouseDoor,
  Star,
  Phone,
  Display,
  Archive,
  Grid,
  List,
  X,
  Cart3,
  Heart,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import MegaMenuPage from "../../src/components/content/megaMenu";
import ScreenResolution from "./utills/screenResolution";

const MenuItem = ({
  itemName,
  haveArrow,
  setShowFunction,
  Icon,
  linkPath,
  handleShowMenu,
}) => {
  const handleShowFunction = () => {
    if (setShowFunction !== "") {
      setShowFunction();
    }
  };
  return (
    <li className="main-list" onClick={handleShowFunction}>
      <div className="main-list-name">
        {linkPath !== "" ? (
          <>
            <Link to={linkPath} className="menu-link">
              <Icon className="i" />
              <h2>{itemName}</h2>
            </Link>
          </>
        ) : (
          <>
            <Icon className="i" />
            <h2 onClick={handleShowMenu}>{itemName}</h2>
          </>
        )}
      </div>
      {haveArrow ? <ChevronDown className="icon" /> : <></>}
    </li>
  );
};

function Menu({ cartNumbers, likedLength }) {
  const [cartItemsNumber, setCartItemsNumber] = useState(cartNumbers);
  const [likedNumber, setLikedNumber] = useState(likedLength);
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const handleShowMenu = () => {
    setShowMenu((prev) => !prev);
  };

  useEffect(() => {
    setCartItemsNumber(cartNumbers);
  }, [cartNumbers]);

  useEffect(() => {
    setLikedNumber(likedLength);
  }, [likedLength]);

  const [resolution, setResolution] = useState(0);

  const handleResolution = (value) => {
    setResolution(value);
  };

  return (
    <div className="out">
      <ScreenResolution setResolution={handleResolution} />
      <section className={`menu `}>
        <div className="right-side-header">
          <Link to={"liked-page"}>
            <div className="heart-profile-header">
              <div className="favorite-icon relative">
                <Heart className="i" />
                <div
                  className="w-4 h-4 rounded-full bg-[#0066ff] absolute
                bottom-[-5px] right-[-5px]"
                >
                  <p
                    className="text-sm text-white top-[50%] left-[50%] 
                  translate-x-[-50%]  translate-y-[-50%]
                  font-bold text-center absolute"
                  >
                    {likedNumber}
                  </p>
                </div>
              </div>
            </div>
          </Link>
          <div className="cart">
            <div className="cart-icon relative ">
              <Link to={"cart"}>
                <Cart3 className="i" />
                <div
                  className="w-4 h-4 rounded-full bg-[#0066ff] absolute
                bottom-[-5px] right-[5px]"
                >
                  <p
                    className="text-sm text-white top-[50%] left-[50%] 
                  translate-x-[-50%]  translate-y-[-50%]
                  font-bold text-center absolute"
                  >
                    {cartItemsNumber}
                  </p>
                </div>
              </Link>
            </div>
            <div className="cart-info">
              <p className="cart-amount">355.00</p>
            </div>
          </div>
          <div
            onClick={() => {
              handleShowMenu();
            }}
            className="main-menu-list-icon"
          >
            <List className="i" />
          </div>
        </div>
        <div
          className={`menu-list z-50 
          ${
            resolution <= 1000 ? (showMenu ? "slide-show" : "slide-hidden") : ""
          }`}
        >
          <ul className="main-ul">
            <div
              onClick={() => {
                handleShowMenu();
              }}
              className="main-menu-list-icon-exit"
            >
              <X className="i" />
            </div>
            <MenuItem
              itemName={"მთავარი"}
              haveArrow={false}
              setShowFunction={""}
              Icon={HouseDoor}
              linkPath={"/"}
              handleShowMenu={handleShowMenu}
            />
            <MenuItem
              itemName={"პოპულარული"}
              haveArrow={false}
              setShowFunction={""}
              Icon={Star}
              linkPath={""}
              handleShowMenu={handleShowMenu}
            />
            <MenuItem
              itemName={"მობილური"}
              haveArrow={true}
              setShowFunction={""}
              Icon={Phone}
              linkPath={""}
              handleShowMenu={handleShowMenu}
            />
            <MenuItem
              itemName={"ტექნიკა"}
              haveArrow={true}
              setShowFunction={""}
              Icon={Display}
              linkPath={""}
              handleShowMenu={handleShowMenu}
            />
            <MenuItem
              itemName={"ავეჯი"}
              haveArrow={true}
              setShowFunction={handleShow}
              Icon={Archive}
              linkPath={""}
              handleShowMenu={handleShowMenu}
            />
            <div className="mega-menu-hover">
              <MenuItem
                itemName={"სხვადასხვა"}
                haveArrow={true}
                setShowFunction={handleShow}
                Icon={Grid}
                linkPath={""}
                handleShowMenu={handleShowMenu}
              />
            </div>
          </ul>
        </div>
        {show? <MegaMenuPage handleShow={handleShow} thisShow={show} /> : <></>}
      </section>
    </div>
  );
}

export default Menu;
