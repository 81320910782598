import {
  ZoomIn,
  ChevronCompactLeft,
  ChevronCompactRight,
  XLg,
} from "react-bootstrap-icons";
import Iphonimg from "../../../imgs/iphon.png";
import Tv5img from "../../../imgs/tv5.png";
import Wachimg from "../../../imgs/wach.png";
import Goproimg from "../../../imgs/gopro.png";
import Laptopimg from "../../../imgs/laptop.png";
import Tvimg from "../../../imgs/tv1.png";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";

const photos = [Iphonimg, Tv5img, Wachimg, Goproimg, Laptopimg, Tvimg];

function ZoomedPht({ handleZoomClick }) {
  let swiperRef = useRef();

  const [currentPhotoPath, setCurrentPhotoPath] = useState(Iphonimg);
  const handlePhotoChange = (newPath) => {
    setCurrentPhotoPath(newPath);
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      console.log(windowSize[0]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  },[]);

  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  useEffect(() => {
    if (windowSize[0] >= 1500) {
      setSlidesToDisplay(6);
    } else  if (windowSize[0] <= 1500 && windowSize[0] >= 1200) {
      setSlidesToDisplay(6);
    } else if (windowSize[0] <= 1200 && windowSize[0] >= 600) {
      setSlidesToDisplay(4);
    } else if (windowSize[0] <= 600 && windowSize[0] >= 500) {
      setSlidesToDisplay(3);
    } else if (windowSize[0] <= 500 && windowSize[0] >= 280) {
      setSlidesToDisplay(2);
    } else{
      setSlidesToDisplay(1);
    }
  }, [windowSize]);

  return (
    <div className="out">
    <div
      className="zoomed-imgs fixed z-[9999999]
      bg-[#808080c2] top-0 left-0 w-[100%] h-[100%] p-[20px]"
    >
      <div
        className="bg-white justify-center p-2
        text-[24px] rounded-[9px] float-right cursor-pointer"
        onClick={handleZoomClick}
      >
        <XLg />
      </div>
      <div className="w-[100%] h-[90%] items-center justify-center flex-col flex">
        <div
          className="bg-[white] w-[60%] max-lg:w-[80%] max-sm:w-[90%] justify-center flex-col 
        rounded-[12px] flex items-center min-[1400px]:w-[1000px]"
        >
          <div className="relative zoomed-big-img w-[100%] h-[400px] max-sm:h-[250px] cursor-zoom-in mb-[10px] p-5 rounded-[12px]">
            {/* <img src={Iphonimg} alt="" className="prod-zoomable-img"></img> */}

            <TransformWrapper>
              <TransformComponent>
                <img
                  src={currentPhotoPath}
                  alt=""
                  className="zoomable-img h-[100%] w-[100%] object-contain rounded-[12px]"
                ></img>
              </TransformComponent>
            </TransformWrapper>

            <div className="zoomed-loop bg-[#9e9e9e33] rounded-full w-[40px] h-[40px] float-right absolute right-[20px] bottom-0 items-center flex flex-col justify-center text-[25px] ">
              <ZoomIn className="i" />
            </div>
          </div>
          <div className="zoomed-phts-list flex w-[100%] items-center border-t border-solid border-gray-300">
            <button
              className="zoomed-prev-arrow"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <p>
                <ChevronCompactLeft className="text-[22px]" />
              </p>
            </button>

            <Swiper
              modules={[EffectFade]}
              slidesPerView={slideToDisplay}
              pagination={{ clickable: false }}
              dots={"false"}
              loop={true}
              className="other-phts-slider"
              disabledclass={"disabled_swiper_button"}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              speed={1000}
              cssease={"linear"}
            >
              {photos.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      onClick={() => {
                        handlePhotoChange(item);
                      }}
                      className="w-full h-[120px] flex items-center flex-col 
                    justify-center contain py-5 px-2 mx-[-3px]"
                    >
                      <img
                        src={item}
                        alt=""
                        className="w-[100%] h-[100%] object-contain cursor-pointer"
                      ></img>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <button
              className="zoomed-next-arrow"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <p>
                <ChevronCompactRight className="text-[22px]" />
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default ZoomedPht;
