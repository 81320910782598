import CartProducts from "../content/cart/cartProducts";
import AsignOrder from "../content/cart/asignOrder";
import YourOrder from "../content/cart/yourOrder";

const CartPage = () => {
  return (
    <>
      <CartProducts />
      <div className="out">
        <div
          className="w-full max-w-[1500px] flex max-xl:flex-wrap gap-10 max-lg:px-[45px] max-sm:px-[20px]
        px-[110px] pt-0 max-md:[pt-0] max-sm:pt-0 margin-bottom-primary"
        >
          <div className="w-8/12 max-xl:w-full">
            <AsignOrder />
          </div>
          <div className="w-4/12 flex items-stretch max-xl:w-full">
            <YourOrder />
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPage;
