import SlidTimer from "./sliderTimer";
import TimSlider from "./timCards";
import SliderSlideComponent from "../sliderComponent/SliderComponent";

function SliderTimer() {
  return (
    <div className="out">
      <section className="one-line-adverts margin-bottom-primary">
        <div className="one-line-header timer-respons">
          <h1 className="title">ტელევიზორები</h1>
          <div>
            <SlidTimer />
          </div>
        </div>
        <div className="border">
          <div className="oneborder"></div>
          <div className="twoborder"></div>
        </div>

        <SliderSlideComponent
          SliderSlideComponent={TimSlider}
        />
      </section>
    </div>
  );
}

export default SliderTimer;