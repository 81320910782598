import { useState,  useEffect } from "react";

const Example = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleShowHide = () => {
    setIsOpen(!isOpen);
  };

  const [value, setValue] = useState([{
    name: "saxeli",
    age: 12,
  },
  {
    name: "saxeli2",
    age: 15,
    surname: ""
  }]);

  const handleDecrement = () => {
    setValue(value - 1);
  };

  const handleIncrement = () => {
    setValue(value + 1);
  };


  useEffect(()=>{
    console.log(true)
  },[isOpen])

  return (
    <div>
      <button
    onClick={handleShowHide}
    >დააჭირე</button>

    {isOpen ?  <div>სიტყვა</div>: <></>}
      <button onClick={handleDecrement}>-</button>
      <p>{
        value.map((object, index)=>{
            return(
                <p style={{background: "red"}}>
                {object.name }
                <br />
                {object.age}
                <br/>
                </p>
            )
        })
        }</p>
      <button onClick={handleIncrement}>+</button>
    </div>
  );
};

export default Example;
