import { Cart4, ExclamationTriangle } from "react-bootstrap-icons";

function Info() {
  return (
    <div className="out">
      <div className="info-page margin-top-primary margin-bottom-primary">
        <h1 className="infos-background">404</h1>
        <div className="info-content">
          <div className="ic">
            <Cart4 className="i" />
          </div>
          <h1 className="attention">ყურადღება!</h1>
          <div className="info-text">
            <div className="icon">
              <ExclamationTriangle className="i excl-mark" />
            </div>
            <p className="info-par">
              შენ მიერ მოძებნილი გვერდი რთულად მისაგნები აღმოჩნდა, მთავარ
              გვერდზე დასაბრუნებლად დააჭირე ლურჯ ღილაკს
            </p>
          </div>
          <h2 className="info-btn">მთავარი გვერდი</h2>
        </div>
      </div>
    </div>
  );
}

export default Info;
