import Specshet from "./specialShet";

import FiveProd from "./fiveNewProdWindow";
import FourProd from "./fourProdWindow";
import PopHorizCards from "./populHorizontalCards";

function SpecialOrders() {
  return (
    <div className="out">
      <section className="big-section-one margin-bottom-primary">
        <div className="special-orders-window">
          <div className="special-orders-window-container">
            <h1 className="special-title">სპეციალური შეთავაზება</h1>
            <Specshet />
            <p className="title-two">აქციის დასრულებამდე დარჩენილია</p>
            <div className="last-time">
              <div className="day">
                <p className="num day-num">55</p>
                <h2 className="txt day-txt">დღე</h2>
              </div>
              <div className="hour">
                <p className="num hour-num">55</p>
                <h2 className="txt hour-txt">საათი</h2>
              </div>
              <div className="min">
                <p className="num min-num">55</p>
                <h2 className="txt min-txt">წუთი</h2>
              </div>
              <div className="sec">
                <p className="num sec-num">55</p>
                <h2 className="txt sec-txt">წამი</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="horiz-popular-products-window">
          <div className="header-of-popular-window">
            <h1 className="title">პოპულარული</h1>
            <div className="new-sale-buy">
              <p className="new-btn">ახალი</p>
              <p className="sale-btn">ფასდაკლება</p>
              <p className="buy-btn">გაყიდვადი</p>
            </div>
          </div>

          <PopHorizCards />

          {/* <FourProd /> */}



          {/* <FiveProd/> */}
        </div>
      </section>
    </div>
  );
}

export default SpecialOrders;
