import PagePath from "../pathOfPages";

import Info from "../content/myinfo/information";

function MyInfo() {
  return (
    <>
      <PagePath />
      <Info/>
    </>
  );
}

export default MyInfo
