import ParagraphWithTitle from "./ParagraphWithTitle";
import bg from "../../../imgs/sky.jpg";
import { Facebook, Linkedin, Clipboard } from "react-bootstrap-icons";
import BlogsCard from "./blogsCard";

const InnerBlogs = () => {
  return (
    <div className="out">
      <div
        className="max-w-[1500px] w-full max-lg:px-[45px] max-sm:px-[20px]
    max-md:py-[45px] px-[110px] py-[90px]"
      >
        <div className="w-full">
          <h1 className="text-[50px] w-full border-b border-gray-200 mb-5">
            ბლოგის სახელი
          </h1>
          <p className="mb-5 text-sm text-gray-500">22.03.2022</p>
        </div>
        <div className="flex max-lg:flex-wrap ">
          <div className="pr-10 relative max-lg:w-1/12 max-sm:w-full max-sm:order-2">
            <div className="flex flex-col gap-2 sticky top-10 left-0 max-sm:flex-row">
              <div
                className="w-8 h-8 bg-[#3a579a] flex items-center justify-center
              rounded-full"
              >
                <Facebook className="text-white text-[20px]" />
              </div>
              <div
                className="w-8 h-8 bg-[#127bb6] flex items-center justify-center
              rounded-full"
              >
                <Linkedin className="text-white text-[20px]" />
              </div>
              <div
                className="w-8 h-8 bg-[#404040] flex items-center justify-center
              rounded-full"
              >
                <Clipboard className="text-white text-[20px]" />
              </div>
            </div>
          </div>
          <div className="gap-10 max-lg:w-11/12 max-sm:w-full max-sm:order-1">
            <div className="pb-10">
              <img
                src={bg}
                alt={"#"}
                className="rounded-lg w-full relative z-20"
              ></img>
            </div>
            <div className="">
              <ParagraphWithTitle
                title={"სათაური ნომერი პირველი"}
                text={`Karabiner 98 kurz („კარაბინი 98 მოკლე“) — გერმანული მაუზერის შაშხანა ხელით
            გადასატენი გრძივმოძრავი საკეტით, რომელიც იყენებს 7.92×57მმ მაუზერის ვაზნებს. გვხვდება
            სხვადასხვა აბრევიატურებით, როგორიცაა: Karabiner 98k, Kar98k ან K98k. 1935 წლის 
            21 ივნისს ვერმახტმა ის შეიარაღებაში მიიღო.`}
              />
              <ParagraphWithTitle
                title={"სათაური ნომერი პირველი"}
                text={`Karabiner 98 kurz („კარაბინი 98 მოკლე“) — გერმანული მაუზერის შაშხანა ხელით
            გადასატენი გრძივმოძრავი საკეტით, რომელიც იყენებს 7.92×57მმ მაუზერის ვაზნებს. გვხვდება
            სხვადასხვა აბრევიატურებით, როგორიცაა: Karabiner 98k, Kar98k ან K98k. 1935 წლის 
            21 ივნისს ვერმახტმა ის შეიარაღებაში მიიღო.`}
              />
            </div>
          </div>
          <div className="pl-8 max-lg:w-full max-sm:order-3 max-lg:pl-0 max-lg:mt-10">
            <div className="mb-5 border-b border-gray-300 pb-3"> 
              <p className="w-full text-center">ბოლოს დამატებული</p>
            </div>
            <div className="flex flex-col gap-10 max-lg:flex-row max-sm:flex-col">
              <BlogsCard />
              <BlogsCard />
              <BlogsCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerBlogs;
