import {
  ZoomIn,
  ChevronCompactLeft,
  ChevronCompactRight,
} from "react-bootstrap-icons";
import Iphonimg from "../../../imgs/iphon.png";
import Tv5img from "../../../imgs/tv5.png";
import Wachimg from "../../../imgs/wach.png";
import Goproimg from "../../../imgs/gopro.png";
import Laptopimg from "../../../imgs/laptop.png";
import Tvimg from "../../../imgs/tv1.png";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade } from "swiper";

import ZoomedPht from "./zoomedImg";

const photos = [Iphonimg, Tv5img, Wachimg, Goproimg, Laptopimg, Tvimg];

function ProdPhotosList({ isMini }) {
  let swiperRef = useRef();

  const [currentPhotoPath, setCurrentPhotoPath] = useState(Iphonimg);
  const handlePhotoChange = (newPath) => {
    setCurrentPhotoPath(newPath);
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      console.log(windowSize[0]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [slideToDisplay, setSlidesToDisplay] = useState(0);
  

  useEffect(() => {
    if (isMini) {
      if (windowSize[0] <= 900 && windowSize[0] >= 800) {
        setSlidesToDisplay(6);
      } else if (windowSize[0] <= 800 && windowSize[0] >= 700) {
        setSlidesToDisplay(5);
      } else if (windowSize[0] <= 700 && windowSize[0] >= 500) {
        setSlidesToDisplay(4);
      } else if (windowSize[0] <= 500 && windowSize[0] >= 400) {
        setSlidesToDisplay(3);
      } else if (windowSize[0] <= 400 && windowSize[0] >= 0) {
        setSlidesToDisplay(1);
      } else {
        setSlidesToDisplay(4);
      }
    } else {
      if (windowSize[0] <= 900 && windowSize[0] >= 700) {
        setSlidesToDisplay(8);
      } else if (windowSize[0] <= 700 && windowSize[0] >= 600) {
        setSlidesToDisplay(6);
      } else if (windowSize[0] <= 600 && windowSize[0] >= 500) {
        setSlidesToDisplay(5);
      } else if (windowSize[0] <= 500 && windowSize[0] >= 400) {
        setSlidesToDisplay(4);
      } else if (windowSize[0] <= 400 && windowSize[0] >= 300) {
        setSlidesToDisplay(3);
      } else {
        setSlidesToDisplay(5);
      }
    }
  }, [windowSize]);

  const [zoomedAble, setZoomedDisplay] = useState(false);

  const handleZoomClick = (event) => {
    setZoomedDisplay((prev) => !prev);
  };

  return (
    <>
      <div className="prod-phts ">
        <div className="big-pht ">
          {/* cursor-zoom-in */}
          {/* <img src={Iphonimg} alt="" className="prod-zoomable-img"></img> */}

          <TransformWrapper>
            <TransformComponent>
              <img
                src={currentPhotoPath}
                alt=""
                className="prod-zoomable-img"
              ></img>
            </TransformComponent>
          </TransformWrapper>

          <div
            className="loop"
            onClick={() => {
              handleZoomClick();
            }}
          >
            <ZoomIn className="i" />
          </div>
        </div>
        <div className="advert-prod-phts">
          <button
            className="prod-slider-prev-arrow"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <p>
              <ChevronCompactLeft />
            </p>
          </button>

          <Swiper
            modules={[EffectFade]}
            slidesPerView={slideToDisplay}
            pagination={{ clickable: false }}
            dots={"false"}
            loop={true}
            className="other-phts-slider"
            disabledclass={"disabled_swiper_button"}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            speed={1000}
            cssease={"linear"}
          >
            {photos.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    onClick={() => {
                      handlePhotoChange(item);
                    }}
                    className="r pht"
                  >
                    <img src={item} alt=""></img>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <button
            className="prod-slider-next-arrow"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <p>
              <ChevronCompactRight />
            </p>
          </button>
        </div>
      </div>
      <div>
        {zoomedAble ? (
          <>
            <ZoomedPht handleZoomClick={handleZoomClick} />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default ProdPhotosList;
