import WholesSlider from "../content/wholeProductsPage/wholeSlider";
import WholeprodContent from "../content/wholeProductsPage/wholeProdCont";
import WholeProdAdv from "../content/wholeProductsPage/wholeprodadv";
import PagePathBackground from "../pagePathBaground";
import ProdCategs from "../content/wholeProductsPage/CategoriesProd";


function WholeProducts(){
    return(
    <>
    <PagePathBackground path={['მთავარი','მობილურ','Apple','Apple iPhone 14']}/> 
    <WholesSlider/>
    <ProdCategs/>
    <WholeprodContent/>
    <WholeProdAdv/>
    </>
    )
}

export default WholeProducts