import { Link } from "react-router-dom";
import logoimg from "../imgs/logo.png";
import flag from "../imgs/ge-flag.png";
import React, { useState, useEffect } from "react";
import {
  Telephone,
  Envelope,
  Coin,
  ArrowLeftRight,
} from "react-bootstrap-icons";
import HoverableDropdown from "./content/hoverableDropdown/hoverableDropdown";

import {
  Search,
  Person,
  Heart,
  Cart3,
  ChevronDown,
} from "react-bootstrap-icons";

const laguageInfo = [
  {
    id: 1,
    imgUrl: flag,
    name: "ქართული (ge)",
  },
  {
    id: 2,
    imgUrl: flag,
    name: "ინგლისური (en)",
  },
  {
    id: 3,
    imgUrl: flag,
    name: "რუსული (ru)",
  },
];

const currencyInfo = [
  {
    id: 1,
    ArrowLeftRight: ArrowLeftRight,
    name: "Eur",
  },
  {
    id: 2,
    ArrowLeftRight: ArrowLeftRight,
    name: "Gel",
  },
  {
    id: 3,
    ArrowLeftRight: ArrowLeftRight,
    name: "Usd",
  },
];

function HeaderTop() {
  return (
    <div className="out border-b-[1px] border-[#cfcfcf]">
      <section className="header-top">
        <div className="header-top-container">
          <div className="hot-line-out">
            <div className="menu-phone-container">
              <div className="tel-icon">
                <a href="mailto:">
                  <Envelope className="i" />
                </a>
              </div>
              <a href="mailto:" className="hot-nom">
                forExample@gmail.com
              </a>
            </div>
          </div>
          <div className="hot-line-out">
            <div className="menu-phone-container">
              <div className="tel-icon">
                <a href="tel:">
                  <Telephone className="i" />
                </a>
              </div>
              <a href="tel:" className="hot-nom">
                (032) 2 11 11 90
              </a>
            </div>
          </div>
        </div>

        <div className="header-top-container">
          <HoverableDropdown
            optionsInfo={laguageInfo}
            initialName={"ქართული (ge)"}
          />
          <HoverableDropdown
            optionsInfo={currencyInfo}
            withIcons={true}
            MainIcon={Coin}
            OptionsIcon={ArrowLeftRight}
            initialName={"ვალუტა"}
          />
          <div className="autorisation">
            <div className="autor-icon">
              <Person className="i" />
              <p>პროფილი</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function Header({ cartNumbers, likedLength }) {
  const [cartItemsNumber, setCartItemsNumber] = useState(cartNumbers);
  const [likedNumber, setLikedNumber] = useState(likedLength);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setCartItemsNumber(cartNumbers);
  }, [cartNumbers]);

  useEffect(() => {
    setLikedNumber(likedLength);
  }, [likedLength]);

  return (
    <>
      <HeaderTop />
      <div className="out header-border">
        <section className="header">
          <div className="page-logo">
            <Link to={"/"}>
              <img src={logoimg} alt=""></img>
            </Link>
          </div>
          <div className="search-bar">
            <div className="categories" onClick={() => setShow(!show)}>
              <div className="left">
                <p className="ttle">კატეგორიები</p>
              </div>
              <ChevronDown className="ic" />
            </div>
            {show && (
              <div className="in-categories scrollbar">
                <ul>
                  <li className="technic">ტექნიკა</li>
                  <li className="saxlis-inventari">
                    სახლის ინვენტარი და ავეჯი
                  </li>
                  <li className="samzareulos-technic">სამზარეულოს ტექნიკა</li>
                  <li className="toys">სათამაშოები</li>
                  <li className="baby-world">ბავშვის სამყარო</li>
                  <li className="office-sakanc">ოფისი და საკანცელარიო</li>
                  <li className="sport-travel">სპორტი და მოგზაურობა</li>
                  <li className="car-world">ავტო სამყარო</li>
                  <li className="handmade">ხელნაკეთი ნივთები</li>
                  <li className="building-instruments">
                    სამშენებლო ინსტრუმენტები
                  </li>
                </ul>
              </div>
            )}
            <div className="header-search">
              <input
                className="search"
                type="search"
                placeholder="პროდუქტების ძებნა..."
              ></input>
              <div className="search-icon">
                <Search className="i" />
              </div>
            </div>
          </div>
          <div className="right-side-header">
            <div className="heart-profile-header">
              <Link to={"liked-page"}>
                <div className="favorite-icon relative">
                  <Heart className="i" />
                  <div
                    className="w-4 h-4 rounded-full bg-[#0066ff] absolute
                bottom-[-5px] right-[-5px]"
                  >
                    <p
                      className="text-sm text-white top-[50%] left-[50%] 
                  translate-x-[-50%]  translate-y-[-50%]
                  font-bold text-center absolute"
                    >
                      {likedNumber}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="cart">
              <div className="cart-icon relative ">
                <Link to={"cart"}>
                  <Cart3 className="i" />
                  <div
                    className="w-4 h-4 rounded-full bg-[#0066ff] absolute
                bottom-[-5px] right-[5px]"
                  >
                    <p
                      className="text-sm text-white top-[50%] left-[50%] 
                  translate-x-[-50%]  translate-y-[-50%]
                  font-bold text-center absolute"
                    >
                      {cartItemsNumber}
                    </p>
                  </div>
                </Link>
              </div>
              <div className="cart-info">
                <p className="cart-amount">355.00</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Header;
