import React, { useState,useEffect } from "react";
import { Dash, Plus } from "react-bootstrap-icons";

function Counter({handleQuantity}) {
  const [count, setCount] = useState(1);
  const handleInc = () => setCount(count + 1);

  const handleDec = () => {
    if (count !== 0) {
      setCount(count - 1);
    }
  };

  useEffect(()=>{
    handleQuantity(count);
  },[count])

  return (
    <div className="amount-click">
      <p className="num">{count}</p>
      <p onClick={handleDec} className="min">
        <Dash className="i" />
      </p>
      <p onClick={handleInc} className="plus">
        <Plus className="i" />
      </p>
    </div>
  );
}

export default Counter;
