import { Route, Routes } from "react-router-dom";
import { createContext, useState, useEffect } from "react";
import "./App.css";
import "./responsive.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import ScrollToTop from "./components/utills/scrollToTop";

import Header from "./components/header";
import Menu from "./components/menu";
import Footer from "./components/footer";
import MainPage from "./components/pages/main";
import ProductPage from "./components/pages/productPage";
import ContactPage from "./components/pages/contact";
import AutoReg from "./components/pages/autoReg";
import WholeProducts from "./components/pages/wholeProducts";
import ForgetPassword from "./components/pages/forgetPassword";
import AboutUsPage from "./components/pages/aboutUsPage";
import InnerBlogsLayout from "./components/pages/InnerBlogsPage";
import MyInfo from "./components/pages/myInfo";
import SuccessPage from "./components/pages/successPage";
import CartPage from "./components/pages/cartPage";
import ProfilePage from "./components/pages/profilePage";
import Example from "./components/pages/example";
import Categories from "./components/pages/categories";
import FaQPage from "./components/pages/faQPage";
import BlogsPage from "./components/pages/blogsPage";
import InvoiceLayout from "./components/content/invoice/invoiceLayout";
import LikedPage from "./components/pages/likedPage";
import MiniDescription from "./components/content/miniDescription/miniDescription.";

import tv1img from "./imgs/tv1.png";

const ProductDataContext = createContext(null);

const getLocalStoreData = (key) => {
  const localData = JSON.parse(localStorage.getItem(key));
  if (localData) {
    return JSON.parse(localStorage.getItem(key));
  } else {
    return [];
  }
};

function App() {
  const [cartProduct, setCartProduct] = useState(
    getLocalStoreData("cartProduct")
  );
  const [likedProduct, setLikedProduct] = useState(
    getLocalStoreData("likedProduct")
  );
  const [cartLength, setCartLength] = useState(0);
  const [likedLength, setLikedLength] = useState(0);

  const handleAddCart = (productOject) => {
    if (cartProduct.length !== 0) {
      var flag = true;
      cartProduct.forEach((item, index) => {
        if (item.id === productOject.id) {
          flag = false;
        }
      });
      if (flag) {
        setCartProduct((prev) => [...prev, productOject]);
      }
    } else {
      setCartProduct([productOject]);
    }
  };

  const handleRemoveCartProduct = (id) => {
    var tmp = cartProduct.filter((item) => item.id !== id);
    setCartProduct((prev) => tmp);
  };

  const handleLike = (productOject) => {
    if (likedProduct.length !== 0) {
      var flag = true;
      likedProduct.forEach((item, index) => {
        if (item.id === productOject.id) {
          flag = false;
        }
      });
      if (flag) {
        setLikedProduct((prev) => [...prev, productOject]);
      }
    } else {
      setLikedProduct([productOject]);
    }
  };

  const handleRemoveLike = (id) => {
    var tmp = likedProduct.filter((item) => item.id !== id);
    setLikedProduct((prev) => tmp);
  };

  useEffect(() => {
    localStorage.setItem("cartProduct", JSON.stringify(cartProduct));
    setCartLength(cartProduct.length);
  }, [cartProduct]);

  useEffect(() => {
    localStorage.setItem("likedProduct", JSON.stringify(likedProduct));
    setLikedLength(likedProduct.length);
  }, [likedProduct]);

  return (
    <ProductDataContext.Provider
      value={{
        data,
        cartProduct,
        handleAddCart,
        handleRemoveCartProduct,
        handleLike,
        handleRemoveLike,
        likedProduct,
      }}
    >
      <ScrollToTop/>
      <Header cartNumbers={cartLength} likedLength={likedLength} />
      <Menu cartNumbers={cartLength} likedLength={likedLength} />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="contact-page" element={<ContactPage />} />
        <Route path="aut-reg" element={<AutoReg />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="about" element={<AboutUsPage />} />
        <Route path="my-information" element={<MyInfo />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="cart" element={<CartPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="example" element={<Example />} />
        <Route path="categories" element={<Categories />} />
        <Route path="faq" element={<FaQPage />} />
        <Route path="invoice" element={<InvoiceLayout />} />
        <Route path="blogs" element={<BlogsPage />} />
        <Route path="blogs/:blogsId" element={<InnerBlogsLayout />} />
        <Route path="whole-products-page" element={<WholeProducts />} />
        <Route
          path="whole-products-page/:productId"
          element={<ProductPage />}
        />
        <Route path="liked-page" element={<LikedPage />} />
        <Route path="new" element={<MiniDescription />} />
      </Routes>
      <Footer />
    </ProductDataContext.Provider>
  );
}

export { App, ProductDataContext };

const data = [
  {
    id: 1,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 2,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 3,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 4,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 5,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 6,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 7,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 8,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 9,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 10,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 11,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 12,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 13,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 14,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 15,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 16,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 17,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 18,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 19,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 20,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 21,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
  {
    id: 22,
    imgUrl: tv1img,
    name: "Apple MKGP3LL",
    price: 1500,
    discountPrice: 1200,
  },
];
