import TimSlider from "./timCards";
import SliderSlideComponent from "../sliderComponent/SliderComponent";

function OneLineAdvertsPart() {
  return (
    <div className="out">
      <section className="one-line-adverts margin-bottom-primary">
        <div className="one-line-header">
          <h1 className="title">ტელევიზორები</h1>
          <div className="new-sale-buyer">
            <p className="news">ახალი</p>
            <p className="sales">ფასდაკლება</p>
            <p className="most-buying">გაყიდვადი</p>
          </div>
        </div>
        <div className="border">
          <div className="oneborder"></div>
          <div className="twoborder"></div>
        </div>

        <SliderSlideComponent
          SliderSlideComponent={TimSlider}
        />
        
      </section>
    </div>
  );
}

export default OneLineAdvertsPart;
