import PagePath from "../pathOfPages";

import CategoriesCont from "../content/categoriespage/CategCont";


const CartPage = () => {
  return (
    <>
      <PagePath/> 
      <CategoriesCont/>
    </>
  );
};

export default CartPage;