import EveryProduct from "./productRow";
import {
  ListUl,
  Person,
  PersonFill,
  GiftFill,
  BagFill,
  GeoAltFill,
  EyeFill,
  LockFill,
  List,
  At,
  Lock,
  DoorOpen,
  PersonGear
} from "react-bootstrap-icons";
import React, { useState } from "react";
import Dropable from "./dropdownMenu";

function SecondMenu() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(prev => !prev)
  }
  return (
    <div className="orders-header w-[100%] flex my-[25px]">
      <div onClick={() => { handleOpen() }} className="cursor-pointer orders-nav flex bg-[#0e6dff] w-[25%]
           justify-between rounded-l-[8px] relative min-w-[230px]">

        <div className="flex m-[10px] ml-[15px] items-center">
          <div className="bg-white w-[40px] h-[40px] rounded-full items-center flex flex-col justify-center mr-[10px]">
          <PersonGear className="text-[27px]"/>
          </div>
          <p className="text-[18px] text-white">პროფილი</p>
        </div>

        {/* <div className="flex m-[15px]">
          <ListUl className="i text-[27px] mr-[10px] text-white" />
          <p className="text-[18px] text-white">ნავიგაცია</p>
          {open ? (<Dropable className="text-white" />) : null}
        </div> */}
      </div>

      <div className="orders-up flex shadow-md success-shadow w-[75%] p-[15px] justify-end rounded-r-[8px] relative">
        <p>მთავარი შეკვეთები</p>
      </div>
    </div>
  )
}

function ProfileContent() {
  const [profleMenuOpen, setProfleMenuOpen] = useState(true);
  const [currentProfileComponent, setCurrentProfileComponent] = useState("redact");

  const handleProfleMenuOpen = () => {
    setProfleMenuOpen(prev => !prev);
  }

  const handleCurrentProfileComponent = (newValue) => {
    setCurrentProfileComponent(prev => newValue);
  }

  return (
    <div className="out">
      <div className="out orderspg flex-col w-[100%] px-[110px] max-w-[1500px]">
        <SecondMenu />
        <div className="order-cat gap-[25px] w-[100%] flex justify-between mb-[20px]">
          <div className="order-left min-w-[230px] w-[25%] rounded-[8px] p-[15px] relative">
            <div className="order-profile flex items-center mb-[10px]">
              <div className="w-[40px] h-[40px] bg-[#e3e3e3] rounded-[50%] flex flex-col justify-center items-center text-[20px] text-[#0e6dff] mr-[10px]">
                <Person />
              </div>
              <div className="order-profile">
                <p className="text-[#a8aab2]">გამარჯობა</p>
                <p className="mt-[-5px]">გიორგი</p>
              </div>
            </div>
            {profleMenuOpen ? (
              
              <ul className="text-[#50535c] relative z-50 max-h-[328px] overflow-y-auto scrollbar mb-[10px]">
                <li
                  onClick={() => { handleCurrentProfileComponent() }}
                  className="flex my-[20px] cursor-pointer">
                  <PersonFill className="mr-[5px]" />
                  ინფორმაცია
                </li>
                <li
                  onClick={() => { handleCurrentProfileComponent() }}
                  className="flex my-[20px] cursor-pointer">
                  <GiftFill className="mr-[5px]" />
                  სასაჩუქრე ბარათები
                </li>
                <li
                  onClick={() => { handleCurrentProfileComponent() }}
                  className="flex my-[20px] cursor-pointer">
                  <GiftFill className="mr-[5px]" />
                  სასაჩუქრე ბარათები
                </li>
                <li
                  onClick={() => { handleCurrentProfileComponent() }}
                  className="flex my-[20px] cursor-pointer">
                  <GiftFill className="mr-[5px]" />
                  სასაჩუქრე ბარათები
                </li>
                <li
                  onClick={() => { handleCurrentProfileComponent() }}
                  className="flex my-[20px] cursor-pointer">
                  <GiftFill className="mr-[5px]" />
                  სასაჩუქრე ბარათები
                </li>
                <li
                  onClick={() => { handleCurrentProfileComponent("orders") }}
                  className="flex my-[20px] cursor-pointer">
                  <BagFill className="mr-[5px]" />
                  შეკვეთები
                </li>
                <li
                  onClick={() => { handleCurrentProfileComponent() }}
                  className="flex my-[20px] cursor-pointer">
                  <GeoAltFill className="mr-[5px]" />
                  მისამართები
                </li>
                <li
                  onClick={() => { handleCurrentProfileComponent() }}
                  className="flex my-[20px] cursor-pointer">
                  <EyeFill className="mr-[5px]" />
                  გამოწერილი ფასები
                </li>
                <li
                  onClick={() => { handleCurrentProfileComponent("redact") }}
                  className="flex my-[20px] cursor-pointer">
                  <LockFill className="mr-[5px]" />
                  პროფილის რედატირება
                </li>
                
              </ul>
              ) : (<></>)}
              <div className="exit-and-menu flex">
              <div className="exit-btn cursor-pointer w-full bg-red-100 hover:bg-[#f4d0d0] flex items-center rounded-lg border border-gray-300">
                  <div className="ex-le border-r border-gray-300 h-12 flex items-center justify-center">
                    <DoorOpen className="text-md text-red-500 w-12" />
                  </div>
                  <div className="ex-ri w-full h-12 rounded-lg border-0 focus:outline-none flex items-center pl-2">
                    <p className="font-bold text-md">გასვლა</p>
                  </div>
                </div>
              <div className="menu-ic-div w-[40px] h-[40px] bg-[#e3e3e3] rounded-[7px] 
                items-center hidden justify-center 
                flex-col text-[27px] hover:bg-[#ececec] cursor-pointer"
                onClick={() => { handleProfleMenuOpen() }}>
              <List className="menu-icon " />
              </div>
</div>
            
            
            
          </div>

          <div className="orders-Content w-[73%] rounded-[8px] h-[470px] p-[15px] ">
            {currentProfileComponent === "orders" &&
              (<div className="w-full">
                <p className="text-[30px] mx-[10px]">შეკვეთები</p>
                <div className="overflow-auto scrollbar h-[395px]">
                <EveryProduct status={"გადახდილია"} />
                <EveryProduct status={"ნივთი გზაშია"} />
                <EveryProduct status={"გაუქმებულია"} />
                <EveryProduct status={"გადახდილია"} />
                <EveryProduct status={"ნივთი გზაშია"} />
                <EveryProduct status={"გაუქმებულია"} />
                </div>
              </div>)
            }
            {currentProfileComponent === "redact" &&
              (<div className="w-full">
                <div className="w-full mt-8">
                  <div className="mb-3">
                    <p className="mb-2 text-md font-medium">თქვენი ელ.ფოსტა</p>
                    <div className="flex items-center rounded-lg border border-gray-300">
                      <div className="border-r border-gray-300 h-12 flex items-center justify-center">
                        <At className="text-md text-gray-400 w-12" />
                      </div>
                      <input
                        type={"mail"}
                        className="w-full h-12  pl-2 rounded-lg border-0 focus:outline-none px-[7px]"
                        placeholder="მაგალითად@gmail.com"
                      ></input>
                    </div>
                  </div>
                  <div className="mb-3">
                    <p className="mb-2 text-md font-medium">ძველი პაროლი</p>
                    <div className="flex items-center rounded-lg border border-gray-300">
                      <div className="border-r border-gray-300 h-12 flex items-center justify-center">
                        <Lock className="text-md text-gray-400 w-12" />
                      </div>
                      <input
                        type={"text"}
                        className="w-full h-12 pl-2 rounded-lg border-0 focus:outline-none px-[7px]"
                      ></input>
                    </div>
                  </div>
                  <div className="mb-3">
                    <p className="mb-2 text-md font-medium">ახალი პაროლი</p>
                    <div className="flex items-center rounded-lg border border-gray-300">
                      <div className="border-r border-gray-300 h-12 flex items-center justify-center">
                        <Lock className="text-md text-gray-400 w-12" />
                      </div>
                      <input
                        type={"text"}
                        className="w-full h-12 pl-2 rounded-lg border-0 focus:outline-none px-[7px]"
                      ></input>
                    </div>
                  </div>
                  <div className="mt-6 max-sm:w-full">
                    <button className="bg-[#007aff] text-white px-4 py-2 rounded-full font-semibold max-sm:w-full">
                      <h2 className="pt-[2px]">შენახვა</h2>
                    </button>
                    <div className="flex"></div>
                  </div>
                </div>
              </div>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileContent;
