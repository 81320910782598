import Advimg from "../../../imgs/phoneback.PNG";

function WholeProdAdv() {
  return (
    <div className="out">
      <div className="whole-advert margin-bottom-primary">
        <img className="Advbg" src={Advimg} alt="#"></img>
        <div className="advtxt">
            <p className="advpart">რეკლამის ადგილი</p>
            <p className="smalladvpart">რეკლამის განსათავსებლად მოგვწერეთ</p>
        </div>
      </div>
    </div>
  );
}

export default WholeProdAdv;
