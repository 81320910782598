import {
  CarFrontFill,
  QrCode,
  CashCoin,
  FileText,
} from "react-bootstrap-icons";

const ShopProductSidebar = ({
  paymentInfo = `ჩვენთან მოქმედებს გადახდა კურიერთან და ასევე საბანკო ანგარიშზე გადმორიცხვა.
    100₾-დან ზემოთ მოქმედებს განვადება (თიბისი,საქართველო)`,
  deliveryInfo,
  promoCodeInfo,

  //გარანტია -- ტექნიკის კატეგორიიდან ნივთის შეძენისას, მისი სერიული 
  //ნომერი ებმება მომხმარებლის ინვოისს და ავღრიცხავთ ელექტრონულად,
  //მოთხოვნის შემთხვევაში შესაძლებელია საგარანტიო ტალონის ბეჭდური 
  //სახით მიწოდება, თუმცა ძირითადად ვიყენებთ ელექტრონულ ტალონებს.
}) => {
  return (
    <div
      className="rounded-lg w-[28%] bg-[#0066ff] max-xl:gap-y-4
            ml-10 p-5 flex flex-col justify-between max-xl:w-full max-xl:m-0 "
    >
      <div>
        <div className="mb-1 flex gap-1 items-center">
          <CashCoin className="text-white text-lg" />
          <h2 className="text-white text-lg pt-1">გადახდა & განვადება</h2>
        </div>
        <p className="text-white text-sm">{paymentInfo}</p>
      </div>
      <div>
        <div className="mb-1 flex gap-1 items-center">
          <CarFrontFill className="text-white text-lg" />
          <h2 className="text-white text-lg pt-1">მიწოდება</h2>
        </div>
        <p className="text-white text-sm">{paymentInfo}</p>
      </div>
      <div>
        <div className="mb-1 flex gap-1 items-center">
          <QrCode className="text-white text-lg" />
          <h2 className="text-white text-lg pt-1">პრომო კოდი</h2>
        </div>
        <p className="text-white text-sm">{paymentInfo}</p>
      </div>
      <div>
        <div className="mb-1 flex gap-1 items-center">
          <FileText className="text-white text-lg" />
          <h2 className="text-white text-lg pt-1">გარანტია</h2>
        </div>
        <p className="text-white text-sm">{paymentInfo}</p>
      </div>
    </div>
  );
};

export default ShopProductSidebar;
