import { Person, At } from "react-bootstrap-icons";
import keyImg from "../../../imgs/sky.jpg";
import CardStyleInputs from "../cardStyleInputs/cardStyleInputs";

const InputFields = () => {
  return (
    <div className="w-full mt-8">
      <div>
        <p className="mb-2">ელ.ფოსტა</p>
        <div className="flex items-center rounded-lg border border-gray-300">
          <div className="border-r border-gray-300 h-12 flex items-center justify-center">
            <At className="text-xl text-gray-400 w-12" />
          </div>
          <input
            type={"mail"}
            className="w-full h-12 rounded-lg border-0 focus:outline-none px-[7px]"
          ></input>
        </div>
      </div>
      <div className="mt-10 max-sm:w-full">
        <button className="bg-[#007aff] text-white px-4  rounded-full max-sm:w-full">
          <h1 className="pt-[9px] pb-[5px]">გაგზავნა</h1>
        </button>
        <div className="flex"></div>
      </div>
    </div>
  );
};

const ForgetContent = () => {
  return (
    <div className="out">
      <div className="w-full max-w-[1500px] w-full max-lg:px-[45px] max-sm:px-[20px]
     px-[110px] flex items-center justify-center margin-bottom-primary margin-top-primary">
        <div className="w-[60%] max-sm:w-full">
          <CardStyleInputs 
            img={keyImg}
            title={"დაგავიწყდათ პაროლი?"}
            Icon={Person}
            descriptionText={"პაროლის აღსადგენად შეიყვანეთ თქვენი ელ-ფოსტა"}
            Fields={InputFields}
            needBanner= {false}
          />
        </div>
      </div>
    </div>
  );
};
export default ForgetContent;
