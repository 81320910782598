import AppleLogo from "../../../imgs/Apple_logo_black.png";
import SamsungLogo from "../../../imgs/Samsung-Logo-2.png";
import XiaomiLogo from "../../../imgs/Xiaomi-Logo-2014.png";
import RealmeLogo from "../../../imgs/Realme-realm.png";
import BoshLogo from "../../../imgs/Bosch-Logo-1981.png";
import SonyLogo from "../../../imgs/Sony-Logo.png";
import LogoLogo from "../../../imgs/logo_01.png";
import { ChevronCompactRight, ChevronCompactLeft } from "react-bootstrap-icons";
import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

function Advslider() {
  let swiperRef = useRef();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      console.log(windowSize[0])
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  useEffect(()=>{
    if(windowSize[0] >= 1200){
      setSlidesToDisplay(5);
    }else if(windowSize[0] <= 1200 && windowSize[0] >= 800){
      setSlidesToDisplay(3);
    }else if(windowSize[0] <= 800 && windowSize[0] >= 500){
      setSlidesToDisplay(2);
    }else{
      setSlidesToDisplay(1);
    }
  },[windowSize])

  return (
    <div className="out">
      <div className="arrowsUpSlider margin-bottom-primary">
        <div className="down-advert">
          <button
            className="partners-slider-prev-arrow"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <ChevronCompactLeft className="larr" />
          </button>

          <Swiper
            modules={[Autoplay, EffectFade]}
            slidesPerView={slideToDisplay}
            pagination={{ clickable: false }}
            dots={"false"}
            loop={true}
            className="partners-slider-imgs"
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            disabledclass={"disabled_swiper_button"}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            speed={1500}
            cssease={"linear"}
          >
            <SwiperSlide>
              <div className="adv">
                <img src={AppleLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={SamsungLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={XiaomiLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={RealmeLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={BoshLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={AppleLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={SamsungLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={XiaomiLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={RealmeLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={BoshLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={SonyLogo} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="adv">
                <img src={LogoLogo} alt=""></img>
              </div>
            </SwiperSlide>
          </Swiper>

          <button
            className="partners-slider-next-arrow"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <ChevronCompactRight className="rarr" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Advslider;
