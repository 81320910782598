import mobimg from "../../../imgs/iphon.png";

function ProductInfo(){
    return(
        <div className="flex justify-between p-[10px] border-[1px] border-[#e7e7e7] rounded-[5px] my-[4px] mx-[10px]">
          <div className="flex justify-between w-[60%]">
            <div className="flex items-center">
              <img src={mobimg} className="w-[50px] mr-[10px]" alt=""></img>
              <p className="font-semibold	">
                JBL Club Bluetooth Headphones Black
              </p>
            </div>
            <div className="flex items-center">
              <p className="text-[gray]">რაოდენობა:</p>
              <p className="font-semibold	">1</p>
            </div>
            <div className="flex items-center">
              <p className="text-[gray]">ფასი:</p>
              <p className="font-semibold	">599 ₾</p>
            </div>
          </div>
          <div className="flex items-center mr-[5%]">
            <p className="text-[gray]">ჯამი:</p>
            <p className="font-semibold	">599 ₾</p>
          </div>
        </div>
    )
}

export default ProductInfo