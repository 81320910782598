import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { ChevronCompactRight, ChevronCompactLeft } from "react-bootstrap-icons";
import { useContext } from "react";
import { ProductDataContext } from "../../../App";
import MiniDescription from "../miniDescription/miniDescription.";

function SliderComponent({
  SliderSlideComponent,
  delay = 2000,
  showMore = true,
  arrowStyleTwo = false,
}) {
  const contextData = useContext(ProductDataContext);
  const [productData, setProductData] = useState(contextData.data);
  const [showMiniDesk, setShowMiniDesk] = useState(false);
  const [miniDescriptionId, setMiniDescriptionId] = useState(null);

  const [myDelay, setMyDelay] = useState(1500);
  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  let swiperRef = useRef();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleShowMiniDesk = () => {
    setShowMiniDesk((prev) => !prev);
  };

  const handleMiniDescriptionId = (id) => {
    setMiniDescriptionId((prev) => id);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (delay) {
      setMyDelay(delay);
    } else {
      setMyDelay(1300);
    }
  }, [delay]);

  useEffect(() => {
    if (windowSize[0] >= 1200) {
      setSlidesToDisplay(5);
    } else if (windowSize[0] <= 1200 && windowSize[0] >= 800) {
      setSlidesToDisplay(3);
    } else if (windowSize[0] <= 800 && windowSize[0] >= 500) {
      setSlidesToDisplay(2);
    } else {
      setSlidesToDisplay(2);
    }
  }, [windowSize]);

  return (
    <div className="slider-mith-more">
      <Swiper
        modules={[Autoplay]}
        slidesPerView={slideToDisplay}
        pagination={{ clickable: false }}
        dots={"false"}
        autoplay={{
          delay: myDelay,
          disableOnInteraction: false,
        }}
        className="new-content-line"
        disabledclass={"disabled_swiper_button"}
        speed={1300}
        cssease={"linear"}
      >
        {productData.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <SliderSlideComponent
                id={item.id}
                imgUrl={item.imgUrl}
                name={item.name}
                price={item.price}
                discountPrice={item.discountPrice}
                handleShowMiniDesk={handleShowMiniDesk}
                handleMiniDescriptionId={handleMiniDescriptionId}
              />
            </SwiperSlide>
          );
        })}
        {showMore ? (
          <SwiperSlide>
            <div className="see-more">
              <p className="see-plus">+</p>
              <p className="see-text">მეტის ნახვა</p>
            </div>
          </SwiperSlide>
        ) : (
          <></>
        )}
      </Swiper>
      {showMiniDesk && (
        <MiniDescription
          miniDescriptionId={miniDescriptionId}
          handleShowMiniDesk={handleShowMiniDesk}
        />
      )}
      <div className={`arrows-div ${arrowStyleTwo ? "arros-div-up" : ""}`}>
        <button
          className="oneLine-slider-prev-arrow"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ChevronCompactLeft />
        </button>

        <button
          className="oneLine-slider-next-arrow"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <ChevronCompactRight />
        </button>
      </div>
    </div>
  );
}

export default SliderComponent;
