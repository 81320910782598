import React, { useRef } from "react";
import ProgBar from "./progressBar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade } from "swiper";
import washmachine from "../../../imgs/washmachine.png";
import iphone from "../../../imgs/iphon.png";
import wach from "../../../imgs/wach.png";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";

function Specshet() {
  let swiperRef = useRef();
  return (
    <div className="slider-imgs">
      <button
        className="leftarr"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        {" "}
        <ArrowLeft className="i" />{" "}
      </button>
      <button
        className="rightarr"
        onClick={() => swiperRef.current?.slideNext()}
      >
        {" "}
        <ArrowRight className="i" />{" "}
      </button>

      <Swiper
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        pagination={{ clickable: false }}
        dots={"false"}
        loop={true}
        className="minislider"
        autoplay={{
          delay: 800,
          disableOnInteraction: false,
        }}
        disabledclass={"disabled_swiper_button"}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        speed={1500}
        cssease={"linear"}
        dotstouchable={"true"}
      >
        <SwiperSlide>
          <div className="one-img">
            <div className="img one">
              <img src={washmachine} alt=""></img>
            </div>
            <div className="quantity">
              <div className="texts">
                <div className="last">
                  <p className="darcha">დარჩა:</p>
                  <p className="number">17</p>
                </div>
                <div className="whole-num">
                  <p className="maragshi">მარაგში:</p>
                  <p className="num">55</p>
                </div>
              </div>
              <div className="range">
                <ProgBar leftAmount={17} totalAmount={55} height={7} />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="one-img">
            <div className="img two">
              <img src={iphone} alt=""></img>
            </div>
            <div className="quantity">
              <div className="texts">
                <div className="last">
                  <p className="darcha">დარჩა:</p>
                  <p className="number">56</p>
                </div>
                <div className="whole-num">
                  <p className="maragshi">მარაგში:</p>
                  <p className="num">75</p>
                </div>
              </div>
              <div className="range">
                <ProgBar leftAmount={56} totalAmount={75} height={7} />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="one-img">
            <div className="img three">
              <img src={wach} alt=""></img>
            </div>
            <div className="quantity">
              <div className="texts">
                <div className="last">
                  <p className="darcha">დარჩა:</p>
                  <p className="number">3</p>
                </div>
                <div className="whole-num">
                  <p className="maragshi">მარაგში:</p>
                  <p className="num">12</p>
                </div>
              </div>
              <div className="range">
                <ProgBar leftAmount={3} totalAmount={12} height={7} />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Specshet;
