import {Person, PersonPlus, At, Lock} from "react-bootstrap-icons"
import {Link} from "react-router-dom";
import Fbiconoimg from "../../../imgs/fb-icon-kai.png"
import Googleiconkai from "../../../imgs/google-icon-kai.jpg"


function AvtoRegCont(){
    return (
        <div className="out">
            <div className="avto-reg-cont margin-bottom-primary margin-top-primary">
                <div className="avto">
                    <div className="icon">
                        <Person className="i"/>
                    </div>
                    <div className="avto-cont">
                        <p className="title">ავტორიზაცია!</p>
                        <p className="mini-title">მოგესალმებით, შედით თქვენი ანგარიშის სამართავად</p>
                        <div className="el">
                            <div className="ttle">
                                <p>შეიყვანეთ ელ.ფოსტა</p>
                                <p className="fifq">*</p>
                            </div>
                            <div className="inp">
                                <div className="ic">
                                    <At className="i"/>
                                </div>
                                <input type="text" className="inputi-elfosta"></input>
                            </div>
                        </div>
                        <div className="password">
                            <div className="ttle">
                                <p>თქვენი პაროლი</p>
                                <p className="fifq">*</p>
                            </div>
                            <div className="inp">
                                <div className="ic">
                                    <Lock className="i"/>
                                </div>
                                <input type="text" className="inputi-password"></input>
                            </div>
                        </div>
                        <div className="bottom-avto">
                            <p className="enter">შესვლა</p>
                            <Link to="../forget-password">
                            <p className="forget-pass">დაგავიწყდა პაროლი?</p>
                            </Link>
                        </div>
                        <p className="bottom-par">ავტორიზაცია სოციალური ქსელებით</p>
                        <div className="soc">
                            <div className="fb">
                                <div className="icn">
                                <img src={Fbiconoimg} alt="" />
                                </div>
                                <p>Facebook-ით შესვლა</p>
                            </div>
                            <div className="google">
                                <div className="icn">
                                    <img src={Googleiconkai} alt="" />
                                </div>
                                <p>Google-ით შესვლა</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reg">
                    <div className="icon">
                        <PersonPlus className="i"/>
                    </div>
                    <div className="reg-cont">
                        <p className="title">რეგისტრაცია!</p>
                        <p className="mini-title">მადლობას გიხდით რომ გადაწყვიტეთ რეგისტრაცია ჩვენთან</p>
                        <div className="your-name">
                            <div className="namm">
                                <p className="name">თქვენი სახელი</p>
                                <p className="fifqi">*</p>
                            </div>
                            <input type="text"></input>
                        </div>
                        <div className="pas">
                            <div className="passs">
                                <p>პაროლი</p>
                            <p className="fifqi">*</p>
                            </div>
                            <input type="text"></input>
                        </div>
                        <div className="same-pas">
                            <div className="same-passs">
                                <p>გაიმეორე პაროლი</p>
                            <p className="fifqi">*</p>
                            </div>
                            <input type="text"></input>
                        </div>
                        <div className="el-fost">
                            <div className="el">
                                <p>შეიყვანეთ ელ.ფოსტა</p>
                            <p className="fifqi">*</p>
                            </div>
                            <input type="text"></input>
                        </div>
                        <div className="last-par">
                            <p className="reg-btn">რეგისტრაციის გაგრძელება</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvtoRegCont