import { useState, useEffect } from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import logo from "../../../imgs/sky.jpg";
import bogimg from "../../../imgs/bankLogos/bog.png";
import tbcimg from "../../../imgs/bankLogos/tbc.png";

const InputComponent = ({
  placeholderText = "placeholder",
  labelName = "labelName",
  inputName,
  handleUserData,
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue((prev) => event.target.value);
  };

  useEffect(() => {
    if (inputValue !== "") {
      handleUserData(inputValue, inputName);
    }
  }, [inputValue]);

  return (
    <div className="input-component">
      <label htmlFor="input-field">{labelName}</label>
      <input
        onChange={(event) => handleInputChange(event)}
        name={inputName}
        type="text"
        placeholder={placeholderText}
      />
    </div>
  );
};

const SelectComponent = ({
  labelName = "labelName",
  selectItems = ["option one", "option two", "option three"],
  inputName,
  handleUserData,
}) => {
  const [selectState, setSelectState] = useState(false);
  const handleSelectState = () => {
    setSelectState((prev) => !prev);
  };

  const [currentValue, setCurrentValue] = useState("აირჩიეთ");
  const handleCurrentValue = (value) => {
    setCurrentValue((prev) => value);
  };

  useEffect(() => {
    if (currentValue !== "აირჩიეთ") {
      handleUserData(currentValue, inputName);
    }
  }, [currentValue]);

  return (
    <div className="input-component">
      <label htmlFor="input-field">{labelName}</label>
      <div className="custom-select">
        <div onClick={handleSelectState} className="current-option-container">
          <p className="current-option">{currentValue}</p>
          {selectState ? <ChevronUp /> : <ChevronDown />}
        </div>
        {selectState ? (
          <div className="options-container">
            {selectItems.map((item, index) => {
              return (
                <p
                  onClick={() => {
                    handleCurrentValue(item);
                    handleSelectState();
                  }}
                  className="option-item"
                  key={index}
                >
                  {item}
                </p>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const RadioInput = ({ imgUrl, value, inputName, handleUserData }) => {
  const handleCurrentValue = () => {
    handleUserData(value, inputName);
  };

  return (
    <div className="invoice-form-rad-img" onClick={handleCurrentValue}>
      <img src={imgUrl} alt="#" />
    </div>
  );
};

const InvoiceBoughtPart = () => {
  return (
    <div className="invoice-dataform-bought-part">
      {broughtItemsData.map((singleObject) => {
        return (
          <div key={singleObject.id} className="invoice-bought-part-items">
            <div>
              <img src={singleObject.img} alt="#" />
            </div>
            <div className="invoice-bought-part-items-data">
              <p>{singleObject.name}</p>
              <p>რაოდენობა: {singleObject.quantity}</p>
              <p>ჯამური ფასი: {singleObject.quantity * singleObject.price}</p>
            </div>
          </div>
        );
      })}

      <div className="invoice-dataform-bought-part-sum">
        <p>ჯამური ფასი</p>
        <p>123₾</p>
      </div>
    </div>
  );
};

const InvoiceDataForm = ({ handleInvoiceData, handleCurrentContent }) => {
  const [userFormData, setUserFormData] = useState({
    userType: "მომხმარებლის ტიპი",
    companyName: "კომპანიის სახელი",
    name: "სახელი",
    surname: " გვარი",
    phone: "ტელეფონი",
    userId: "მომხმარებლის პირადი",
    email: "მეილი",
    deliveryMethod: "მიწოდები მეთოდი",
    payentMethod: "გადახდის მეთოდი",
    paymentBank: "გადახდის ბანკი",
  });

  const handleUserData = (value, inputName) => {
    setUserFormData((prev) => ({ ...prev, [inputName]: value }));
  };

  useEffect(() => {
    handleInvoiceData(userFormData);
  }, [userFormData]);

  return (
    <div className="invice-form-component">
      <div className="invoice-form">
        <h1>პირადი ინფორმაცია</h1>
        <div className="invoice-form-fields">
          <SelectComponent
            labelName={"მომხმარებლის ტიპი"}
            selectItems={["option one", "option two", "option three"]}
            handleUserData={handleUserData}
            inputName={"userType"}
          />
          <InputComponent
            placeholderText={"Busines Makers"}
            labelName="კომპანიი სახელი"
            handleUserData={handleUserData}
            inputName={"companyName"}
          />
          <div className="two-horiontal-input">
            <div className="two-horiontal-input-item">
              <InputComponent
                placeholderText={"სახელი"}
                labelName="მომხმარებლის სახელი"
                handleUserData={handleUserData}
                inputName={"name"}
              />
            </div>
            <div className="two-horiontal-input-item">
              <InputComponent
                placeholderText={"გვარი"}
                labelName="მომხმარებლის გვარი"
                handleUserData={handleUserData}
                inputName={"surname"}
              />
            </div>
          </div>
          <div className="two-horiontal-input">
            <div className="two-horiontal-input-item">
              <InputComponent
                placeholderText={"+995 577 12 12 12"}
                labelName="ტელეფონი"
                handleUserData={handleUserData}
                inputName={"phone"}
              />
            </div>
            <div className="two-horiontal-input-item">
              <InputComponent
                placeholderText={"1234XXXXXXX"}
                labelName="პირადი ნომერი"
                handleUserData={handleUserData}
                inputName={"userId"}
              />
            </div>
          </div>
          <InputComponent
            placeholderText={"forexample@gmail.com"}
            labelName="ელექტრონული ფოსტა"
            handleUserData={handleUserData}
            inputName={"email"}
          />

          <SelectComponent
            labelName={"მიწოდების მეთოდი"}
            selectItems={["option one", "option two", "option three"]}
            handleUserData={handleUserData}
            inputName={"deliveryMethod"}
          />
          <SelectComponent
            labelName={"გადახდის მეთოდი მეთოდი"}
            selectItems={["option one", "option two", "option three"]}
            handleUserData={handleUserData}
            inputName={"payentMethod"}
          />
          <div>
            <h1>სასურველი ბანკი</h1>
            <div className="invoice-form-rad-img-container">
              <RadioInput
                imgUrl={tbcimg}
                value={"TBC"}
                inputName={"paymentBank"}
                handleUserData={handleUserData}
              />
              <RadioInput
                imgUrl={bogimg}
                value={"BOG"}
                inputName={"paymentBank"}
                handleUserData={handleUserData}
              />
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                handleCurrentContent("invoice");
              }}
            >
              შემდეგი
            </button>
          </div>
        </div>
      </div>
      <div className="invoice-bought-items">
        <InvoiceBoughtPart />
      </div>
    </div>
  );
};

export default InvoiceDataForm;

const broughtItemsData = [
  {
    id: 1,
    img: logo,
    name: "Aiphone 14",
    code: "1376WIOS",
    price: 212,
    quantity: 2,
  },
  {
    id: 2,
    img: logo,
    name: "Aiphone 14",
    code: "1376WIOS",
    price: 212,
    quantity: 1,
  },
  {
    id: 3,
    img: logo,
    name: "Aiphone 14",
    code: "1376WIOS",
    price: 212,
    quantity: 2,
  },
];
