import SuccessContent from "../content/successContent/successContent";

const SuccessPage = () => {
  return (
    <>
      <SuccessContent success={false}/>
    </>
  );
};

export default SuccessPage;
