import { Eye, Heart, HeartFill, CartFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { ProductDataContext } from "../App";
import CountdownTimer from "./countdowntimer";

function Cardsebi({
  id = 1,
  imgUrl = "",
  name = "name",
  price = 0,
  discountPrice = 0,
  handleShowMiniDesk,
  handleMiniDescriptionId,
}) {
  const addCartFunction = useContext(ProductDataContext).handleAddCart;
  const addLikedFunction = useContext(ProductDataContext).handleLike;
  const handleRemoveLike = useContext(ProductDataContext).handleRemoveLike;
  const likedProduct = useContext(ProductDataContext).likedProduct;
  const [heartIconClicked, setHeartIconClicked] = useState(false);

  useEffect(() => {
    var tmp = likedProduct.filter((item) => item.id === id);
    if (tmp.length !== 0) {
      setHeartIconClicked(true);
    } else {
      setHeartIconClicked(false);
    }
  }, [likedProduct]);

  const [thisData, setThisData] = useState({
    id: id,
    imgUrl: imgUrl,
    name: name,
    price: price,
    discountPrice: discountPrice,
  });

  return (
    <div className="whole-card">
      <div className="mainCard">
        <div className="image-part">
          <div className="img">
            <img src={imgUrl} alt=""></img>
          </div>
        </div>
        <Link to={`../whole-products-page/${id}`}>
          <p className="prod-name">{name}</p>
        </Link>
        <div className="old-new-price">
          <p className="old">{price}</p>
          <p className="new">{discountPrice}</p>
        </div>
        <div className="card-bottom">
          <div
            onClick={() => {
              addCartFunction(thisData);
            }}
            className="add-to-cart"
          >
            <p className="texti">კალათაში</p>
            <div className="srch">
              <CartFill className="icon" />
            </div>
          </div>
          <div className="icons">
            <div
              onClick={() => {
                handleShowMiniDesk();
                handleMiniDescriptionId(id);
              }}
              className="srch cursor-pointer"
            >
              <Eye />
            </div>
            {heartIconClicked ? (
              <div
                className="srch"
                onClick={() => {
                  setHeartIconClicked((prev) => !prev);
                  handleRemoveLike(id);
                }}
              >
                <HeartFill />
              </div>
            ) : (
              <div
                className="srch"
                onClick={() => {
                  setHeartIconClicked((prev) => !prev);
                  addLikedFunction(thisData);
                }}
              >
                <Heart />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="timer-area">
        <CountdownTimer textBlack={true} />
      </div>
    </div>
  );
}

export default Cardsebi;
