import { Eye, Heart, HeartFill, CartFill } from "react-bootstrap-icons";
import advoneimg from "../../../imgs/advone.png";
import advtwoimg from "../../../imgs/advtwo.png";
import advthreeimg from "../../../imgs/advthree.png";
import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { ProductDataContext } from "../../../App";
import MiniDescription from "../miniDescription/miniDescription.";

function Card({
  rightBannersShow,
  id = 1,
  imgUrl = "",
  name = "name",
  price = 0,
  discountPrice = 0,
  handleShowMiniDesk,
  handleMiniDescriptionId,
}) {
  const addCartFunction = useContext(ProductDataContext).handleAddCart;
  const addLikedFunction = useContext(ProductDataContext).handleLike;
  const handleRemoveLike = useContext(ProductDataContext).handleRemoveLike;
  const likedProduct = useContext(ProductDataContext).likedProduct;
  const [heartIconClicked, setHeartIconClicked] = useState(false);

  useEffect(() => {
    var tmp = likedProduct.filter((item) => item.id === id);
    if (tmp.length !== 0) {
      setHeartIconClicked(true);
    } else {
      setHeartIconClicked(false);
    }
  }, [likedProduct, id]);

  const [thisData, setThisData] = useState({
    id: id,
    imgUrl: imgUrl,
    name: name,
    price: price,
    discountPrice: discountPrice,
  });

  return (
    <div className={`cards ${rightBannersShow ? "" : "rightBannersShowFalse"}`}>
      <div className="icons">
        <div
          onClick={() => {
            handleShowMiniDesk();
            handleMiniDescriptionId(id);
          }}
          className="srch cursor-pointer"
        >
          <Eye />
        </div>
        {heartIconClicked ? (
          <div
            className="srch cursor-pointer"
            onClick={() => {
              setHeartIconClicked((prev) => !prev);
              handleRemoveLike(id);
            }}
          >
            <HeartFill />
          </div>
        ) : (
          <div
            className="srch cursor-pointer"
            onClick={() => {
              setHeartIconClicked((prev) => !prev);
              addLikedFunction(thisData);
            }}
          >
            <Heart />
          </div>
        )}
      </div>
      <div className="image-part">
        <div className="img">
          <img src={imgUrl} alt=""></img>
        </div>
      </div>
      <Link to={`../whole-products-page/${id}`}>
        <p className="prod-name">{name}</p>
      </Link>
      <div className="card-bottom">
        <p className="price">{price}</p>
        <div
          onClick={() => {
            addCartFunction(thisData);
          }}
          className="add-to-cart"
        >
          <p className="texti">კალათაში</p>
          <div className="srch">
            <CartFill className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

function LastBigAdvertPart({ rightBannersShow = true }) {
  const contextData = useContext(ProductDataContext);
  const [productData, setProductData] = useState(contextData.data);
  const [showMiniDesk, setShowMiniDesk] = useState(false);
  const [miniDescriptionId, setMiniDescriptionId] = useState(null);

  const handleShowMiniDesk = () => {
    setShowMiniDesk((prev) => !prev);
  };

  const handleMiniDescriptionId = (id) => {
    setMiniDescriptionId((prev) => id);
  };

  return (
    <div className="out">
      <section className="last-big-advert-part margin-bottom-primary">
        <div className="last-big-advert-header">
          <h1 className="title">ტელევიზორები</h1>
          <div className="new-sale-buyer">
            <p className="newss">ახალი</p>
            <p className="sales">ფასდაკლება</p>
            <p className="most-buying">გაყიდვადი</p>
          </div>
        </div>
        <div className="border">
          <div className="oneborder"></div>
          <div className="twoborder"></div>
        </div>
        <div className="last-big-advert-content">
          <div
            className={`left-cont items-center ${
              rightBannersShow ? "" : "width-full-imp"
            }`}
          >
            <div className="up-left-cont">
              {productData.map((item, index) => {
                if (index <= 5) {
                  return (
                    <Card
                      key={item.id}
                      rightBannersShow={rightBannersShow}
                      id={item.id}
                      imgUrl={item.imgUrl}
                      name={item.name}
                      price={item.price}
                      discountPrice={item.discountPrice}
                      handleShowMiniDesk={handleShowMiniDesk}
                      handleMiniDescriptionId={handleMiniDescriptionId}
                    />
                  );
                }
              })}
              {rightBannersShow ? (
                <></>
              ) : (
                <>
                  <Card
                    rightBannersShow={rightBannersShow}
                    handleShowMiniDesk={handleShowMiniDesk}
                    handleMiniDescriptionId={handleMiniDescriptionId}
                  />
                  <Card
                    rightBannersShow={rightBannersShow}
                    handleShowMiniDesk={handleShowMiniDesk}
                    handleMiniDescriptionId={handleMiniDescriptionId}
                  />
                </>
              )}
            </div>
            <Link to={`../whole-products-page/`}>
            <p className="mt-[20px] bg-[#0066ff] text-[white] p-[10px] px-[14px] rounded-[10px] cursor-pointer hover:shadow-primary">
              მეტის ნახვა
            </p>
            </Link>
          </div>
          {rightBannersShow ? (
            <div className="right-cont">
              <div className="im up-advertisment">
                <img src={advoneimg} alt=""></img>
              </div>
              <div className="im mid-advertisment">
                <img src={advtwoimg} alt=""></img>
              </div>
              <div className="im down-advertisment">
                <img src={advthreeimg} alt=""></img>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
      {showMiniDesk && (
        <MiniDescription
          miniDescriptionId={miniDescriptionId}
          handleShowMiniDesk={handleShowMiniDesk}
        />
      )}
    </div>
  );
}

export default LastBigAdvertPart;
