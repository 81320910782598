import ProductCategori from "../mainpage/catsProduct"

function AdvertSlider(){
    return(
        <>
        <ProductCategori/>
        <ProductCategori/>
        <ProductCategori/>
        <ProductCategori/>
        <ProductCategori/>
        <ProductCategori/>
        <ProductCategori/>
        <ProductCategori/>
        <ProductCategori/>
        </>
    )
}

export default AdvertSlider