const ParagraphWithTitle = ({ title, text }) => {
    return (
      <div className="mb-10">
        <h1 className="text-xl font-bold">{title}</h1>
        <p className="text-md text-gray-500">{text}</p>
      </div>
    );
  };

export default ParagraphWithTitle;
