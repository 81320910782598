import { useState } from "react";
import { ChevronDown } from "react-bootstrap-icons";
import flag from "../../../imgs/ge-flag.png";

const HoverableDropdown = ({
    optionsInfo,
    withIcons = false,
    MainIcon,
    initialName
}) => {
    const [currentImg, setCurrentImg] = useState(flag);
    const [currentName, setCurrentName] = useState(initialName)


    const handleCurentImgChange = (imgUrl) => {
        setCurrentImg(prev => imgUrl)
    }

    const handleCurrentName = (newName) => {
        setCurrentName(prev => newName)
    }

    const handleClickOption = (imgUrl,newName) =>{
        if(MainIcon !== 0){
            handleCurentImgChange(imgUrl);
        }
        handleCurrentName(newName);
    }

    return (
        <div className="group relative">
            <div className="flex items-center gap-1 w-full">
                {!withIcons ?
                    (<img
                        className="w-4 h-4 rounded-full"
                        src={currentImg} alt={"#"} />) :
                    (<MainIcon className="text-[#0066ff]"/>)}

                <p className="text-[14px] max-md:hidden">{currentName}</p>
                <ChevronDown className="text-[10px] text-[#0066ff]" />
            </div>
            <div className="flex flex-col gap-y-2 rounded-lg
             shadow-primary p-2 absolute bottom-0 right-0 bg-white
             translate-y-[100%] z-50 hidden group-hover:flex w-full
             min-w-[110px]">
                {
                    optionsInfo.map((item, index) => {
                        return (
                            <div key={item.id}
                                onClick={() => {handleClickOption(item.imgUrl,item.name)}}
                                className="flex items-center gap-1 rounded-lg
                                hover:shadow-md p-1">
                                {!withIcons ?
                                    (<img src={item.imgUrl} alt={"#"}
                                        className="w-4 h-4 rounded-full" />) :
                                    (<item.ArrowLeftRight className="text-[#0066ff]"/>)}
                                <p className="text-[14px]">{item.name}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default HoverableDropdown;