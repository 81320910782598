import { X, Plus, Dash } from "react-bootstrap-icons";
import { useState, useEffect, useContext } from "react";
import { ProductDataContext } from "../../../App";

const NumberRiser = ({ handleQuantity,quantity }) => {
  const [value, setValue] = useState(quantity);

  const handleIncrement = () => {
    setValue(value + 1);
  };

  const handleDecrement = () => {
    if (value !== 0) {
      setValue(value - 1);
    }
  };

  const handleOnchange = (e) => {
    setValue(parseInt(e.target.value));
  };

  useEffect(() => {
    handleQuantity(value);
  }, [value]);

  useEffect(()=>{
    setValue(quantity)
  },[quantity])

  return (
    <div className="flex items-center justify-center gap-2">
      <div
        onClick={handleDecrement}
        className="rounded-full w-6 h-6 bg-[#0e6dff] cursor-pointer"
      >
        <Dash className="text-white text-2xl" />
      </div>
      <input
        value={value}
        onChange={(e) => {
          handleOnchange(e);
        }}
        type="number"
        className="border border-gray-300 rounded-lg h-8 w-20
        focus:outline-none text-center"
      />
      <div
        onClick={handleIncrement}
        className="rounded-full w-6 h-6 bg-[#0e6dff] cursor-pointer"
      >
        <Plus className="text-white text-2xl" />
      </div>
    </div>
  );
};

const ProductRow = ({
  productName,
  productImg,
  price,
  handleDeleteFromCart,
  itemId,
  itemQuantity
}) => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantity = (newValue) => {
    setQuantity((prev) => newValue);
  };

  useEffect(()=>{
    if(itemQuantity !== undefined){
      setQuantity(itemQuantity);
    }else{
      setQuantity(1);
    }
  },[itemQuantity])

  return (
    <div
      className="w-full flex border border-gray-300 border-t-0 
    last:rounded-bl-lg last:rounded-br-lg"
    >
      <div className="w-7/12 border-r border-gray-300 flex items-center gap-4  p-2">
        <img src={productImg} alt="#" className="w-10"></img>
        <p className="font-bold">{productName}</p>
      </div>
      <div className="w-2/12 border-r border-gray-300 flex items-center justify-center  p-2">
        <NumberRiser handleQuantity={handleQuantity} quantity={quantity}/>
      </div>
      <div className="w-2/12 border-r border-gray-300 flex items-center justify-center select-none p-2">
        <p className="font-bold">{price * quantity} ₾</p>
      </div>
      <div className="w-1/12 flex items-center justify-center p-2">
        <X
          onClick={() => {
            handleDeleteFromCart(itemId);
          }}
          className="text-2xl text-gray-500 text-[#0e6dff] hover:text-red-5100"
        />
      </div>
    </div>
  );
};

const CartProducts = () => {
  const contextData = useContext(ProductDataContext);
  const [cartProductData, setCartProductData] = useState([]);
  const deleteCartProduct = contextData.handleRemoveCartProduct;

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("cartProduct"));
    if (localData) {
      return setCartProductData(JSON.parse(localStorage.getItem("cartProduct")));
    } else {
      return setCartProductData([]);
    }
  }, []);

  useEffect(() => {
    setCartProductData(contextData.cartProduct);
  }, [contextData.cartProduct]);

  return (
    <div className="out">
      <div
        className="w-full max-w-[1500px] max-lg:px-[45px] max-sm:px-[20px]
       px-[110px] margin-top-primary margin-bottom-primary"
      >
        <div className="w-full overflow-x-auto scrollbar">
          <div className="max-xl:w-[1000px] ">
            <div className="bg-[#0e6dff] rounded-tl-lg rounded-tr-lg">
              <div className="w-full flex p-2">
                <div className="w-7/12 flex items-center gap-4">
                  <p className="text-white">პროდუქცია</p>
                </div>
                <div className="w-2/12 flex items-center justify-center">
                  <p className="text-white">რაოდენობა</p>
                </div>
                <div className="w-2/12 flex items-center justify-center">
                  <p className="text-white">ფასი</p>
                </div>
                <div className="w-0.5/12 flex items-center justify-center"></div>
              </div>
            </div>
            {cartProductData.map((item) => {
              return (
                <ProductRow
                  key={item.id}
                  productName={item.name}
                  productImg={item.imgUrl}
                  price={item.price}
                  handleDeleteFromCart={deleteCartProduct}
                  itemId={item.id}
                  itemQuantity = {item.quantity}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProducts;
