import { useState } from "react";
import InvoiceDataForm from "./invoiceDataForm";
import Invoice from "./innvoice";

const InvoiceLayout = () => {
  const [invoiceData, setInvoiceData] = useState({});
  const handleInvoiceData = (newData) => {
    setInvoiceData((prev) => ({ ...newData }));
  };

  const [currentContent, setCuurrentCntent] = useState("invoice-form");
  const handleCurrentContent = (value) => {
    setCuurrentCntent((prev) => value);
  };

  return (
    <>
      <div className="out">
        <div className="invice-form-component-wrapper margin-bottom-primary margin-top-primary">
          {currentContent === "invoice-form" ? (
            <InvoiceDataForm
              handleInvoiceData={handleInvoiceData}
              handleCurrentContent={handleCurrentContent}
            />
          ) : currentContent === "invoice" ? (
            <Invoice invoiceData={invoiceData} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceLayout;
