import { useState , useEffect } from "react";
import { Plus, Dash } from "react-bootstrap-icons";

const Accordion = ({
    question,
    answer,
    selected,
    index
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        if(index === selected){
            setIsOpen(true);
        }else{
            setIsOpen(false);
        }
    },[selected])

    return (
        <div className="single-accordion">
            <div
                className="accordion-question">
                <p >{question}</p>
                {isOpen ?
                    (<Dash className="i"/>) :
                    (<Plus className="i"/>)
                }
            </div>
            <div className={`accordion-answer ${isOpen? "slid-down-effect":"slid-up-effect"}`}>
                <p className={`${isOpen? "fade-in-effect":"fade-out-effect"}`}>{answer}</p>
            </div>
        </div>
    )
}

export default Accordion;