import AppleLogo from "../../../imgs/Apple_logo_black.png";
import SamsungLogo from "../../../imgs/Samsung-Logo-2.png";
import XiaomiLogo from "../../../imgs/Xiaomi-Logo-2014.png";
import RealmeLogo from "../../../imgs/Realme-realm.png";
import BoshLogo from "../../../imgs/Bosch-Logo-1981.png";
import SonyLogo from "../../../imgs/Sony-Logo.png";
import LogoLogo from "../../../imgs/logo_01.png";

import { useRef,useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade } from "swiper";

function WholesSlider() {
  let swiperRef = useRef();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      console.log(windowSize[0])
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [slideToDisplay, setSlidesToDisplay] = useState(0);

  useEffect(()=>{
    if(windowSize[0] >= 1200){
      setSlidesToDisplay(7);
    }else if(windowSize[0] <= 1200 && windowSize[0] >= 600){
      setSlidesToDisplay(4);
    }else{
      setSlidesToDisplay(2);
    }
  },[windowSize])

  return (
    <div className="out">
      <div className="whole-page-down-advert margin-bottom-primary margin-top-primary">
        <button
          className="whole-page-slider-prev-arrow"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <p>❮</p>
        </button>

        <Swiper
          modules={[Autoplay, EffectFade]}
          slidesPerView={slideToDisplay}
          pagination={{ clickable: false }}
          dots={"false"}
          loop={true}
          className="whole-page-slider-imgs"
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          disabledclass={"disabled_swiper_button"}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          speed={2000}
          cssease={"linear"}
        >
          <SwiperSlide>
            <div className="adv">
              <img src={AppleLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={SamsungLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={XiaomiLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={RealmeLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={BoshLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={AppleLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={SamsungLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={XiaomiLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={RealmeLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={BoshLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={SonyLogo} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="adv">
              <img src={LogoLogo} alt=""></img>
            </div>
          </SwiperSlide>
        </Swiper>

        <button
          className="whole-page-slider-next-arrow"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <p>❯</p>
        </button>
      </div>
    </div>
  );
}

export default WholesSlider;
