import PagePath from "../pathOfPages";
import FaqsPage from "../content/faqPage/faqsPage";


function FaQPage(){
    return(
        <>
        <PagePath/> 
        <FaqsPage/>
        </>
    )
}


export default FaQPage