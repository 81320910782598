import {
  Eye,
  GridFill,
  Heart,
  HeartFill,
  CartFill,
  HddStackFill,
  Filter,
} from "react-bootstrap-icons";
import { useState, useContext, useEffect } from "react";
import { ProductDataContext } from "../../../App";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import MiniDescription from "../miniDescription/miniDescription.";

function ProductCardOne({
  id = 1,
  imgUrl = "",
  name = "name",
  price = 0,
  discountPrice = 0,
  handleShowMiniDesk,
  handleMiniDescriptionId,
}) {
  const addCartFunction = useContext(ProductDataContext).handleAddCart;
  const addLikedFunction = useContext(ProductDataContext).handleLike;
  const handleRemoveLike = useContext(ProductDataContext).handleRemoveLike;
  const likedProduct = useContext(ProductDataContext).likedProduct;
  const [heartIconClicked, setHeartIconClicked] = useState(false);

  useEffect(() => {
    var tmp = likedProduct.filter((item) => item.id === id);
    if (tmp.length !== 0) {
      setHeartIconClicked(true);
    } else {
      setHeartIconClicked(false);
    }
  }, [likedProduct, id]);

  const [thisData, setThisData] = useState({
    id: id,
    imgUrl: imgUrl,
    name: name,
    price: price,
    discountPrice: discountPrice,
  });

  return (
    <div className="cards">
      <div className="icons">
        <div
          onClick={() => {
            handleShowMiniDesk();
            handleMiniDescriptionId(id);
          }}
          className="srch cursor-pointer"
        >
          <Eye />
        </div>
        {heartIconClicked ? (
          <div
            className="srch"
            onClick={() => {
              setHeartIconClicked((prev) => !prev);
              handleRemoveLike(id);
            }}
          >
            <HeartFill />
          </div>
        ) : (
          <div
            className="srch"
            onClick={() => {
              setHeartIconClicked((prev) => !prev);
              addLikedFunction(thisData);
            }}
          >
            <Heart />
          </div>
        )}
      </div>
      <div className="image-part">
        <div className="img">
          <img src={imgUrl} alt=""></img>
        </div>
      </div>
      <Link to={`../whole-products-page/${id}`}>
        <p className="prod-name">{name}</p>
      </Link>
      <div className="card-bottom">
        <p className="price">{price}</p>
        <div
          onClick={() => {
            addCartFunction(thisData);
          }}
          className="add-to-cart"
        >
          <p className="texti">კალათაში</p>
          <div className="srch">
            <CartFill className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

function ProductCardTwo({
  id = 1,
  imgUrl = "",
  name = "name",
  price = 0,
  discountPrice = 0,
  handleShowMiniDesk,
  handleMiniDescriptionId,
}) {
  const addCartFunction = useContext(ProductDataContext).handleAddCart;
  const addLikedFunction = useContext(ProductDataContext).handleLike;
  const handleRemoveLike = useContext(ProductDataContext).handleRemoveLike;
  const likedProduct = useContext(ProductDataContext).likedProduct;
  const [heartIconClicked, setHeartIconClicked] = useState(false);

  useEffect(() => {
    var tmp = likedProduct.filter((item) => item.id === id);
    if (tmp.length !== 0) {
      setHeartIconClicked(true);
    } else {
      setHeartIconClicked(false);
    }
  }, [likedProduct, id]);

  const [thisData, setThisData] = useState({
    id: id,
    imgUrl: imgUrl,
    name: name,
    price: price,
    discountPrice: discountPrice,
  });

  return (
    <div className="w-full flex justify-between p-6 rounded-lg box-shadow bg-white max-sm:flex-col max-sm:relative">
      <div className="flex items-center gap-3 cursor-pointer">
        <div className="image-part max-sm:w-[32%]">
          <div className="img">
            <img src={imgUrl} alt=""></img>
          </div>
        </div>
        <Link to={`../whole-products-page/${id}`}>
          <p className="prod-name max-sm:h-[100%] max-sm:items-top max-sm:pt-[10px]">
            {name}
          </p>
        </Link>
      </div>

      <div className="flex flex-col items-center gap-3 justify-center">
        <div className="flex items-center gap-1 max-sm:absolute  max-sm:top-6 max-sm:right-6">
          <div
            onClick={() => {
              handleShowMiniDesk();
              handleMiniDescriptionId(id);
            }}
            className="cursor-pointer rounded-full h-8 w-8 bg-gray-200 flex items-center justify-center hover:bg-[#c1c1c1]"
          >
            <Eye />
          </div>
          {heartIconClicked ? (
            <div
              className="cursor-pointer rounded-full h-8 w-8 bg-gray-200 flex items-center justify-center hover:bg-[#c1c1c1]"
              onClick={() => {
                setHeartIconClicked((prev) => !prev);
                handleRemoveLike(id);
              }}
            >
              <HeartFill />
            </div>
          ) : (
            <div
              className="cursor-pointer rounded-full h-8 w-8 bg-gray-200 flex items-center justify-center hover:bg-[#c1c1c1]"
              onClick={() => {
                setHeartIconClicked((prev) => !prev);
                addLikedFunction(thisData);
              }}
            >
              <Heart />
            </div>
          )}
        </div>
        <div className="flex flex-col items-center gap-3 justify-center max-sm:flex-row max-sm:w-[100%] max-sm:justify-between mt-[10px]">
          <p className="text-xl font-bold">{price}$</p>
          <div
            onClick={() => {
              addCartFunction(thisData);
            }}
            className="kalatatwo flex gap-3 bg-[#06f] items-center rounded-full p-1 px-2 cursor-pointer"
          >
            <p className="text-white text-lg">კალათაში</p>
            <div className="h-6 w-6 bg-white rounded-full flex items-center justify-center">
              <CartFill className="text-[#06f]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PaginPart({ handleFilterFlag }) {
  const contextData = useContext(ProductDataContext);
  const [productData, setProductData] = useState(contextData.data);
  const [cardState, setCardState] = useState(false);
  const [dataSource, setDataSource] = useState(Array.from({ length: 1 }));
  const [hasMore, setHasMore] = useState(true);
  const [showMiniDesk, setShowMiniDesk] = useState(false);
  const [miniDescriptionId, setMiniDescriptionId] = useState(null);

  const handleShowMiniDesk = () => {
    setShowMiniDesk((prev) => !prev);
  };

  const handleMiniDescriptionId = (id) => {
    setMiniDescriptionId((prev) => id);
  };

  const fetchMoreData = () => {
    setTimeout(() => {
      setDataSource(dataSource.concat(Array.from({ length: 1 })));
    }, 500);
  };

  return (
    <div className="pagin-side">
      <div className="pagin-up">
        <h1 className="title">მობილურები</h1>
        <div className="iconebi">
          <div
            onClick={() => {
              handleFilterFlag();
            }}
            className="products-filter-button"
          >
            <Filter
              className="i fixed w-[36px] h-[100px] bg-[#0066ff] top-[50%] left-0 
            translate-y-[-50%] rounded-r-[47px] text-white cursor-pointer z-[9999]"
            />
          </div>
          <div
            onClick={() => {
              setCardState(false);
            }}
          >
            <GridFill className="i" />
          </div>
          <div
            onClick={() => {
              setCardState(true);
            }}
          >
            <HddStackFill className="i" />
          </div>
        </div>
      </div>
      <div className="border">
        <div className="leftborder"></div>
      </div>

      <div className="products">
        <InfiniteScroll
          dataLength={dataSource.length}
          // next={fetchMoreData}
          hasMore={hasMore}
          // loader={<p className="loader">იტვირთება...</p>}
          className="infinity-scroll"
        >
          {dataSource.map((item, index) => {
            return (
                productData.map((item, indexTwo) => {
                  if (indexTwo <= 15) {
                    return cardState ? (
                      <ProductCardTwo
                        key={item.id}
                        id={item.id}
                        imgUrl={item.imgUrl}
                        name={item.name}
                        price={item.price}
                        discountPrice={item.discountPrice}
                        handleShowMiniDesk={handleShowMiniDesk}
                        handleMiniDescriptionId={handleMiniDescriptionId}
                      />
                    ) : (
                      <ProductCardOne
                        key={item.id}
                        id={item.id}
                        imgUrl={item.imgUrl}
                        name={item.name}
                        price={item.price}
                        discountPrice={item.discountPrice}
                        handleShowMiniDesk={handleShowMiniDesk}
                        handleMiniDescriptionId={handleMiniDescriptionId}
                      />
                    );
                  }
                })
            );
          })}
          <div className="products-load-more-container">
            <button onClick={fetchMoreData}>მეტის ნახვა</button>
          </div>
        </InfiniteScroll>
      </div>
      {showMiniDesk && (
        <MiniDescription
          miniDescriptionId={miniDescriptionId}
          handleShowMiniDesk={handleShowMiniDesk}
        />
      )}
    </div>
  );
}

export { PaginPart, ProductCardTwo };
