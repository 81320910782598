import AboutUsContent from "../content/aboutUsContent/aboutUsContent";
import PriorityLayout from "../content/aboutUsContent/prioritys";

const AboutUsPage = () => {
  return (
    <>
      <AboutUsContent />
      <PriorityLayout/>
    </>
  );
};

export default AboutUsPage;
