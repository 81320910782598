import { useState } from "react";
import { useContext } from "react";
import { ProductDataContext } from "../../../App";
import Horcard from "./horizcard";
import MiniDescription from "../miniDescription/miniDescription.";

function PopHorizCards() {
  const contextData = useContext(ProductDataContext);
  const [productData, setProductData] = useState(contextData.data);
  const [miniDescriptionId, setMiniDescriptionId] = useState(null);
  const [showMiniDesk, setShowMiniDesk] = useState(false);

  const handleShowMiniDesk = () => {
    setShowMiniDesk((prev) => !prev);
  };

  const handleMiniDescriptionId = (id) => {
    setMiniDescriptionId((prev) => id);
  };
  return (
    <div className="horiz-new-products-window">
      {productData.map((item, index) => {
        if (index <= 5) {
          return (
            <Horcard
              key={item.id}
              id={item.id}
              imgUrl={item.imgUrl}
              name={item.name}
              price={item.price}
              discountPrice={item.discountPrice}
              handleShowMiniDesk={handleShowMiniDesk}
              handleMiniDescriptionId={handleMiniDescriptionId}
            />
          );
        }
      })}
      {showMiniDesk && (
        <MiniDescription
          miniDescriptionId={miniDescriptionId}
          handleShowMiniDesk={handleShowMiniDesk}
        />
      )}
    </div>
  );
}

export default PopHorizCards;
