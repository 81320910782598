const ProductInfoStyleTwo = ({
  descriptionName = "მახასიათებელი",
  descriptionText = "მახასიათებლის აღწერა",
}) => {
  return (
    <div className="w-full flex mb-1 max-sm:flex-col">
      <div className="bg-gray-200 w-[30%] p-6 max-sm:w-full">
        <p>{descriptionName}</p>
      </div>
      <div className="bg-gray-100 w-[70%] p-6 max-sm:w-full">
        <p>{descriptionText}</p>
      </div>
    </div>
  );
};

export default ProductInfoStyleTwo;
