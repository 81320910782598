const AsignOrder = () => {
  return (
    <div>
      <h2 className="text-2xl text-bold pb-3 border-b border-gray-300">
        შეკვეთის გაფორმება
      </h2>
      <div className="mt-3">
        <div className="flex justify-between flex-wrap">
          <div className="w-[48%] max-sm:w-full">
            <p className="mb-2 text-bold">სახელი გვარი</p>
            <div className="flex items-center rounded-lg border border-gray-300">
              <input
                type={"mail"}
                className="w-full h-10 rounded-lg border-0 focus:outline-none"
              ></input>
            </div>
          </div>
          <div className="w-[48%] text-bold max-sm:w-full">
            <p className="mb-2">მობილური</p>
            <div className="flex items-center rounded-lg border border-gray-300">
              <input
                type={"mail"}
                className="w-full h-10 rounded-lg border-0 focus:outline-none"
              ></input>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-3 flex-wrap">
          <div className="w-[48%] max-sm:w-full">
            <p className="mb-2 text-bold">ზუსტი მისამართი</p>
            <div className="flex items-center rounded-lg border border-gray-300">
              <input
                type={"mail"}
                className="w-full h-10 rounded-lg border-0 focus:outline-none"
              ></input>
            </div>
          </div>
          <div className="w-[48%] text-bold max-sm:w-full">
            <p className="mb-2">გადახდის მეთოდი</p>
            <div className="flex items-center rounded-lg border border-gray-300">
              <select className="w-full h-10 rounded-lg border-0 focus:outline-none">
                <option>მეთოდი</option>
                <option>მეთოდი</option>
                <option>მეთოდი</option>
                <option>მეთოდი</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 flex justify-between items-center max-sm:flex-col">
        <div className="flex gap-2 items-center max-sm:w-full">
          <input
            type={"checkbox"}
            className="rounded-lg border-0 focus:outline-none"
          ></input>
          <p>მე წავიკითხე და ვეთანხმები ვე-გვერდის წესებს</p>
        </div>
        <div className="max-sm:w-full">
          <button className="bg-[#0e6dff] px-4 py-2 rounded-full text-white mt-5 max-sm:w-full">
            შეკვეთის დასრულება
          </button>
        </div>
      </div>
    </div>
  );
};

export default AsignOrder;
