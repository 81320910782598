import { Eye, Heart, HeartFill, CartFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { ProductDataContext } from "../../../App";

function Horcard({
  id = 1,
  imgUrl = "",
  name = "name",
  price = 0,
  discountPrice = 0,
  handleShowMiniDesk,
  handleMiniDescriptionId,
}) {
  const addCartFunction = useContext(ProductDataContext).handleAddCart;
  const addLikedFunction = useContext(ProductDataContext).handleLike;
  const handleRemoveLike = useContext(ProductDataContext).handleRemoveLike;
  const likedProduct = useContext(ProductDataContext).likedProduct;
  const [heartIconClicked, setHeartIconClicked] = useState(false);

  useEffect(() => {
    var tmp = likedProduct.filter((item) => item.id === id);
    if (tmp.length !== 0) {
      setHeartIconClicked(true);
    } else {
      setHeartIconClicked(false);
    }
  }, [likedProduct, id]);

  const [thisData, setThisData] = useState({
    id: id,
    imgUrl: imgUrl,
    name: name,
    price: price,
    discountPrice: discountPrice,
  });

  return (
    <div className="card">
      <div className="card-img-info">
        <div className="image-part">
          <div className="img">
            <img src={imgUrl} alt=""></img>
          </div>
        </div>
        <Link to={`../whole-products-page/${id}`}>
          <p className="prod-name">{name}</p>
        </Link>
        <div className="hvricn icons ">
          <div
            onClick={() => {
              handleShowMiniDesk();
              handleMiniDescriptionId(id);
            }}
            className="srch cursor-pointer"
          >
            <Eye className="" />
          </div>
          {heartIconClicked ? (
            <div
              className="srch cursor-pointer"
              onClick={() => {
                setHeartIconClicked((prev) => !prev);
                handleRemoveLike(id);
              }}
            >
              <HeartFill />
            </div>
          ) : (
            <div
              className="srch cursor-pointer"
              onClick={() => {
                setHeartIconClicked((prev) => !prev);
                addLikedFunction(thisData);
              }}
            >
              <Heart />
            </div>
          )}
        </div>
      </div>
      <div className="card-bottom">
        <p className="price">{price}</p>
        <div
          onClick={() => {
            addCartFunction(thisData);
          }}
          className="add-to-cart"
        >
          <p className="texti">კალათაში</p>
          <div className="srch">
            <CartFill className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Horcard;
