import cardBg from "../../../imgs/sky.jpg";
import { Link } from "react-router-dom";

const BlogsCard = ({ id, img = cardBg, blogTitle }) => {
  return (
    <div className="w-[22%] h-[300px] relative max-lg:w-[30%] max-md:w-[48%] max-sm:w-[100%]">
      <img src={img} alt="#" className="w-full h-full rounded-lg" />
      <div className="absolute w-full h-full top-0 left-0 p-5 flex flex-col justify-between">
        <div className="flex items-center justify-between">
          <Link to={`../blogs/${id}`}>
            <p className="rounded-lg bg-[#007aff] px-4 py-2 text-white">ვრცლად</p>
          </Link>
        </div>
        <div>
          <div>
            <h2 className="text-xl text-white font-bold border-b border-white pb-2 mb-2">
              {blogTitle}
            </h2>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-white">
              ადმინისტრატორმა
            </p>
            <p className="text-white">
              12/02/22
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsCard;
