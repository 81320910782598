import { useContext, useState, useEffect } from "react";
import { ProductDataContext } from "../../App";
import { ProductCardTwo } from "../content/wholeProductsPage/pagination";
import PagePath from "../pathOfPages";

const LikedPage = () => {
  const dataContext = useContext(ProductDataContext).likedProduct;
  const [likedProducData, setLikedProductData] = useState([]);

  useEffect(() => {
    setLikedProductData(dataContext);
  }, [dataContext]);

  return (
    <>
      <PagePath />
      <div className="out">
        <div
          className="w-[100%] flex  max-w-[1500px] gap-5 flex-wrap
    max-lg:px-[45px] max-sm:px-[20px] px-[110px] margin-bottom-primary margin-top-primary"
        >
          {likedProducData.map((item) => {
            return (
              <div className="w-[48%] max-lg:w-full" key={item.id}>
                <ProductCardTwo
                  id={item.id}
                  imgUrl={item.imgUrl}
                  name={item.name}
                  price={item.price}
                  discountPrice={item.discountPrice}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LikedPage;
