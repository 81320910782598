import {useState, useEffect} from "react";

const ScreenResolution = ({setResolution}) =>{
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
          console.log(windowSize[0])
        };
    
        window.addEventListener("resize", handleWindowResize);
    
        return () => {
          window.removeEventListener("resize", handleWindowResize);
        };
      });

      useEffect(()=>{setResolution(windowSize[0])},[windowSize])

    return <></>
}

export default ScreenResolution;