import NewYear from "../../../imgs/sale-bg.png";
import Cardsebi from "../../cardsebi";
import SliderComponentTwo from "../sliderComponent/SliderComponentTwo";

function SalePart() {
  return (
    <div className="out saleBg imagebg margin-bottom-primary">
      <img src={NewYear} alt="" className="imag" />
        <div className="sale-cont">
          <p className="title">საახალწლო შეთავაზებები</p>
          <SliderComponentTwo
            SliderSlideComponent={Cardsebi}
          />
        </div>
    </div>
  );
}

export default SalePart;  