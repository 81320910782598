import bg from "../imgs/sky.jpg";
import { ArrowRight } from "react-bootstrap-icons";

function PagePathBackground({ path }) {
  return (
    <div className="out foni">
      <img
        src={bg}
        className="absolute z-10 w-full h-full top-0 left-0"
        alt="#"
      ></img>
      <section className="path-style-two items-center gap-5">
        {path.map((item, index) => {
          return (
            <div key={index} className="flex items-center gap-2">
              <ArrowRight className="text-white" />
              <p>{item}</p>
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default PagePathBackground;
