import { useRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { ExclamationCircle, Download } from "react-bootstrap-icons";

import logo from "../../../imgs/sky.jpg";

const PdfContent = ({ invoiceData, orderData, innerRef }) => {
  const [data, setData] = useState(broughtItemsData);
  const [fullPrice, setFullPrice] = useState(0);

  const handleFullPrice = () => {
    data.forEach((object) => {
      setFullPrice((prev) => prev + object.price * object.quantity);
    });
  };

  useEffect(() => {
    handleFullPrice();
  }, []);

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()} 
    ${current.getHours()}:${current.getMinutes() + 1}:${current.getSeconds()}`;

  return (
    <div ref={innerRef} className="invoice-container">
      <div className="invoice-head">
        <div className="invoice-comp-info">
          <div>
            <p>შპს BMStore</p>
            <p>მგალობლიშვილი 1</p>
          </div>
          <p className="invoice-date">{date}</p>
        </div>
        <div className="invoice-logo-cont">
          <img src={logo} className="invoicelogo" alt="#" />
          <p>შეკვეთის N:{orderData.orderUid}</p>
        </div>
      </div>

      <div className="invoice-bought-items-container">
        <div className="invoice-bought-items-wrapper">
          <div className="bought-item-head">
            <div></div>
            <div>
              <p>სახელი</p>
            </div>
            <div>
              <p>კოდი</p>
            </div>
            <div>
              <p>ფასი</p>
            </div>
            <div>
              <p>რაოდენობა</p>
            </div>
            <div>
              <p>ჯამი</p>
            </div>
          </div>
          {data.map((singleObject) => {
            return (
              <BoughtItemUi key={singleObject.id} itemData={singleObject} />
            );
          })}
        </div>
      </div>

      <div className="invoice-user-info">
        <div className="invoice-user-info-cont">
          <p className="invoice-user-info-cont-title">მიმღები</p>
          <p>
            {invoiceData.name} {invoiceData.surname}
          </p>
          <p>ტელეფონი: {invoiceData.phone}</p>
          <p>ელ-ფოსტა: {invoiceData.email}</p>
          <p>პ/ნ: {invoiceData.userId}</p>
        </div>

        <div className="invoice-user-info-cont">
          <p className="invoice-user-info-cont-title">შემკვეთი</p>
          <p>
            {invoiceData.name} {invoiceData.surname}
          </p>
          <p>ტელეფონი: {invoiceData.phone}</p>
          <p>ელ-ფოსტა: {invoiceData.email}</p>
          <p>პ/ნ: {invoiceData.userId}</p>
        </div>
        <div className="invoice-user-info-cont">
          <p className="invoice-user-info-cont-title">დეტალები</p>
          <p>მიწოდების მეთოდი: {invoiceData.deliveryMethod}</p>
          <p>კომპანიის სახელი: {invoiceData.companyName}</p>
          <p>მომხმარებლის ტიპი: {invoiceData.userType}</p>
          <p>გადახდიის ტიპი: {invoiceData.payentMethod}</p>
        </div>
      </div>

      <div className="ivoice-warning">
        <ExclamationCircle className="i" />
        <p>
          ინვოისი ძლაშია 3 (სამი) დღის ვადით
          {`${current.getDate() + 3}/${
            current.getMonth() + 1
          }/${current.getFullYear()}`}{" "}
          - მდე
        </p>
      </div>

      <div className="invoice-order-summ">
        <div className="invoice-signature-containner">
          <div className="invoice-signature">ხელმოწერა</div>
          <img src={logo} className="invoicelogo" alt="#" />
        </div>
        <div>
          <p>სულ: {fullPrice} ₾</p>
          <p>მიწოდება:{14} ₾</p>
          <p>ჯამში (დღგ-ს ჩათვლით): 1000 ₾</p>
        </div>
      </div>

      <div className="invoice-banks-container">
        <div className="invoice-single-bank">
          <img src={logo} className="invoicelogo" alt="#" />
          <p>ბანკის ინფორმაცია</p>
        </div>
        <div className="invoice-single-bank">
          <img src={logo} className="invoicelogo" alt="#" />
          <p>ბანკის ინფორმაცია</p>
        </div>
        <div className="invoice-single-bank">
          <img src={logo} className="invoicelogo" alt="#" />
          <p>ბანკის ინფორმაცია</p>
        </div>
      </div>
    </div>
  );
};

const BoughtItemUi = ({ itemData }) => {
  const [data, setData] = useState(itemData);
  const [totalSum, setToalSum] = useState("");
  useEffect(() => {
    setData((prev) => itemData);
    setToalSum(data.price * data.quantity);
  }, [itemData]);
  return (
    <div className="bought-item">
      <div>
        <img src={data.img} alt={data.name} />
      </div>
      <div>
        <p>{data.name}</p>
      </div>
      <div>
        <p>{data.code}</p>
      </div>
      <div>
        <p>{data.price}</p>
      </div>
      <div>
        <p>{data.quantity}</p>
      </div>
      <div>
        <p>{totalSum}</p>
      </div>
    </div>
  );
};

const Invoice = ({ invoiceData }) => {
  var printableElement = useRef();

  return (
    <div className="invoice-container-wrapper">
      <ReactToPrint
        trigger={() => (
          <div className="invoice-download-div">
           
            <button><Download/> დაბეჭდვა / გადმოწერა</button>
          </div>
        )}
        content={() => printableElement.current}
      />
      <PdfContent
        innerRef={printableElement}
        invoiceData={invoiceData}
        printableElement={printableElement}
        orderData={orderData}
      />
    </div>
  );
};

export default Invoice;

const orderData = {
  orderUid: 124319231263,
};

const broughtItemsData = [
  {
    id: 1,
    img: logo,
    name: "Aiphone 14",
    code: "1376WIOS",
    price: 212,
    quantity: 2,
  },
  {
    id: 2,
    img: logo,
    name: "Aiphone 14",
    code: "1376WIOS",
    price: 212,
    quantity: 1,
  },
  {
    id: 3,
    img: logo,
    name: "Aiphone 14",
    code: "1376WIOS",
    price: 212,
    quantity: 2,
  },
];
