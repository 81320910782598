import GoogleMap from "../content/contactpage/googleMap";
import ContactContent from "../content/contactpage/contactContent";
import PagePath from "../pathOfPages";

function ContactPage() {
  return (
    <>
      <PagePath/> 
      <GoogleMap/>
      <ContactContent/>
    </>
  );
}

export default ContactPage;