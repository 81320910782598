import { ArrowDownCircle } from "react-bootstrap-icons";
import React,{useState} from "react";
import ProductInfo from "./myProdInCart";


function EveryProduct({ status }) {
  const [show, setShow]=useState(false)
  return (
    <div className="table-title border-[1px] border-[#dadada] rounded-[7px] my-[15px] mr-[10px] min-w-[870px]  ">
      <div onClick={()=>setShow(!show)} className="ease-in duration-300 flex justify-between w-[100%] cursor-pointer py-[10px] px-[10px]">
        <div className="id">
          <p className="text-[gray]">შეკვეთის ნომერი</p>
          <p className="font-semibold	">#25225255242512</p>
        </div>
        <div className="date">
          <p className="text-[gray]">თარიღი</p>
          <p className="font-semibold	">18 იან,2023</p>
        </div>
        <div className="sum">
          <p className="text-[gray]">შეკვეთის ჯამი</p>
          <p className="text-[gray] font-semibold">599 ₾</p>
        </div>
        <div className="paying">
          <p className="text-[gray]">გადახდის მეთოდი</p>
          <p className="font-semibold	">საქართველოს ბანკის განვადება</p>
        </div>
        <div className="status">
          <p className="text-[gray]">სტატუსი</p>
          <p
            className={`${status === "გადახდილია" ? "textgreen" : ""}
             ${status === "ნივთი გზაშია" ? "textblue" : ""}
             ${status === "გაუქმებულია" ? "textred" : ""}
             font-semibold`}
          >
            {status}
          </p>
        </div>
      </div>
      {show?
      <div className="ease-in duration-300">
        <div className="w-[100%] border-t-[1px] border-[#dadada] p-[10px] ">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-[gray]">ფილიალიდან გატანა/მყიდველი:</p>
              <p className="font-semibold	">გიორგი ჩიქოიანი</p>
            </div>
            <div className="flex  items-center">
              <div className="mr-[30px]">
                <p className="text-[gray]">ელ.ფოსტა:</p>
                <p className="font-semibold	">chiqoianigiorguna@gmail.com</p>
              </div>
              <div className="mr-[30px]">
                <p className="text-[gray]">ტელ.ნომერი:</p>
                <p className="font-semibold	">591-585-443</p>
              </div>
              <div className="flex items-center mr-[10px] bg-[orange] px-[8px] py-[4px] cursor-pointer rounded-[20px] text-white">
                <ArrowDownCircle className="mr-[5px]" />
                <p>ინვოისი</p>
              </div>
            </div>
          </div>
        </div>
        <ProductInfo/>
        <ProductInfo/>

        <div className="flex justify-between mt-[10px] bg-[#e7e7e7] rounded-b-[5px] px-[10px] py-[5px]">
          <div className="flex">
            <p className="text-[gray]">მისამართი:</p>
            <p className="font-semibold">
              "სითი მოლი"-პეტრე ქავთარაძის N1, თბილისი
            </p>
          </div>
          <div className="flex">
            <div className="flex mr-[15px]">
              <p className="text-[gray]">შეფუთვა:</p>
              <p className="font-semibold">0</p>
            </div>
            <div className="flex mr-[15px]">
              <p className="text-[gray]">მიწოდება:</p>
              <p className="font-semibold">0</p>
            </div>
            <div className="flex mr-[5px]">
              <p className="text-[gray]">ჯამი:</p>
              <p className="font-semibold">599</p>
            </div>
          </div>
        </div>
      </div>:null
}
    </div>
  );
}

export default EveryProduct;
