import {
  CartFill,
  Heart,
  HeartFill,
  Twitter,
  Youtube,
  Facebook,
  X,
} from "react-bootstrap-icons";
import { useState, useContext, useEffect } from "react";
import { ProductDataContext } from "../../../App";
import Counter from "../productpage/counter";
import ProdPhotosList from "../productpage/prodPhotosList";

function MiniDescription({ passedId = 1, handleShowMiniDesk }) {
  const cartData = useContext(ProductDataContext).data[passedId];
  const [productData, setProductData] = useState([]);
  const addCartFunction = useContext(ProductDataContext).handleAddCart;
  const addLikedFunction = useContext(ProductDataContext).handleLike;
  const handleRemoveLike = useContext(ProductDataContext).handleRemoveLike;
  const likedProduct = useContext(ProductDataContext).likedProduct;
  const [heartIconClicked, setHeartIconClicked] = useState(false);
  const [productQuantity, setProductQuantity] = useState(0);

  const handleQuantity = (value, show) => {
    setProductQuantity((prev) => value);
  };

  useEffect(() => {
    setProductData(cartData);
  }, []);

  useEffect(() => {
    var tmp = likedProduct.filter((item) => item.id === productData.id);
    if (tmp.length !== 0) {
      setHeartIconClicked(true);
    } else {
      setHeartIconClicked(false);
    }
  }, [likedProduct]);

  useEffect(() => {
    setProductData((prev) => ({ ...prev, quantity: productQuantity }));
  }, [productQuantity]);

  return (
    <div
      className="zoomed-imgs fixed z-[9999999]  flex items-center justify-center
    bg-[#808080c2] top-0 left-0 w-[100%] h-[100%] p-[20px]"
    >
      <div onClick={handleShowMiniDesk} className="absolute top-5 right-5 bg-white rounded-[9px] justify-center cursor-pointer text-[41px]">
        <X className="" />
      </div>
      <div className="prod-frst-info prod-frst-info-mini shadow-primary scrollbar h-[650px]">
        <ProdPhotosList isMini={true}/>
        <div className="about-prod">
          <p className="prod-name">Apple Iphone 14 Pro | 128GB White</p>
          <div className="prod-name-code">
            <p className="maragshia">პროდუქტი მარაგშია</p>
            <p className="code">#2501</p>
          </div>

          <div className="price">
            <p className="new-price">4555</p>
            <p className="old-price">5199</p>
          </div>
          <p className="prod-colors">ფერები</p>
          <div className="prod-colors-div">
            <div className="bl">
              <div className="c blue"></div>
            </div>
            <div className="bl">
              <div className="c black"></div>
            </div>
            <div className="bl">
              <div className="c gray"></div>
            </div>
            <div className="bl">
              <div className="c red"></div>
            </div>
            <div className="bl g">
              <div className="c green"></div>
            </div>
          </div>
          <p className="amount">რაოდენობა</p>
          <div className="amount-cart-heart">
            <div>
              <Counter handleQuantity={handleQuantity} />
            </div>
            <div className="flex items-center gap-4">
              <div
                onClick={() => {
                  addCartFunction(productData);
                }}
                className="ad-cart"
              >
                <p className="cart-title">დამატება</p>
                <div className="srch">
                  <CartFill className="i" />
                </div>
              </div>
              {heartIconClicked ? (
                <div
                  className="heart"
                  onClick={() => {
                    setHeartIconClicked((prev) => !prev);
                    handleRemoveLike(productData.id);
                  }}
                >
                  <HeartFill />
                </div>
              ) : (
                <div
                  className="heart"
                  onClick={() => {
                    setHeartIconClicked((prev) => !prev);
                    addLikedFunction(productData);
                  }}
                >
                  <Heart />
                </div>
              )}
            </div>
          </div>

          <div className="prod-small-info">
            <div className="prod-code">
              <p className="code-title">პროდუქტის კოდი:</p>
              <p className="code">AB4555</p>
            </div>
            <div className="prod-brand">
              <p className="brand-title">ბრენდი:</p>
              <p className="brand">Apple</p>
            </div>
            <div className="prod-issue">
              <p className="issue-title">გამოშვების თარიღი:</p>
              <p className="issue">2022 Seqtember</p>
            </div>
            <div className="prod-condition">
              <p className="cond-title">მდგომარეობა:</p>
              <p className="condition">ახალი</p>
            </div>
          </div>

          <div className="internet">
            <div className="bg-[#06f] p-2 rounded-full">
              <Facebook className="i hov " />
            </div>
            <div className="bg-[#06f] p-2 rounded-full">
              <Twitter className="i hov " />
            </div>
            <div className="bg-[#06f] p-2 rounded-full">
              <Youtube className="i hov " />
            </div>
            <p className="share">გაზიარება</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MiniDescription;
