import { CheckLg, XLg, Info } from "react-bootstrap-icons";

const SuccessContent = ({ success }) => {
  return (
    <div className="out">
      <div className="succsess-page w-[100%] flex justify-center flex-col 
      max-lg:px-[45px] max-sm:px-[20px] px-[110px] items-center margin-bottom-primary margin-top-primary">
        <div className="success-shadow p-10 w-[50%] max-lg:w-[80%] max-lg:w-[100%] flex 
        flex-col items-center justify-center gap-3 h-100 mb-10 rounded-[22px]">
          <div
            className={`rounded-full p-5 ${
              success ? "bg-green-200 " : "bg-red-200"
            }`}
          >
            {success ? (
              <CheckLg className="text-[100px] text-white" />
            ) : (
              <XLg className="text-[100px] text-white" />
            )}
          </div>
          <h2 className="text-2xl font-bold">ინფორმაცია</h2>
          <div className="flex-col items-center justify-center">
            {success ? (
              <p className="text-md text-green-400 text-center">
                თქვენი შეკვეთა წარმატებით გაიგზავნა
              </p>
            ) : (
              <p className="text-md text-[red] text-center">
                სამწუხაროდ თქვენი შეკვეთა ვერ გაიგზავნა
              </p>
            )}

            <p className="text-md font-bold text-center">
              გაყიდვების მენეჯერი მალე დაგიკავშირდება
            </p>
          </div>
          <button className="bg-[#0e6dff] px-4 py-2 rounded-full text-white mt-5 hover:bg-[#4c93ff]">
            <p>მთავარი გვერდი</p>
          </button>
        </div>
        <div className="w-[50%] max-lg:w-[80%] max-lg:w-[100%]">
          <div className="flex items-center mb-[20px]">
            <div className="w-[40px] h-[40px] bg-[#0e6dff] rounded-[50%] mr-[10px] flex flex-col justify-center items-center text-[50px] text-white">
              <Info />
            </div>
            <p className="w-[90%] font-semibold">
              {" "}
              გადმორიცხვის შემთხვევაში გთხოვთ, დანიშნულებაში მიუთითოთ შეკვეთის
              ნომერი #56546546456
            </p>
          </div>
          <div className="flex items-center mb-[20px]">
            <div className="w-[40px] h-[40px] bg-[#0e6dff] rounded-[50%] mr-[10px] flex flex-col justify-center items-center text-[50px] text-white">
              <Info />
            </div>
            <p className="w-[90%] font-semibold">
              {" "}
              გადმორიცხვის შემთხვევაში გთხოვთ, დანიშნულებაში მიუთითოთ შეკვეთის
              ნომერი #56546546456
            </p>
          </div>
          <div className="flex items-center">
            <div className="w-[40px] h-[40px] bg-[#0e6dff] rounded-[50%] mr-[10px] flex flex-col justify-center items-center text-[50px] text-white">
              <Info />
            </div>
            <p className="w-[90%] font-semibold">
              {" "}
              გადმორიცხვის შემთხვევაში გთხოვთ, დანიშნულებაში მიუთითოთ შეკვეთის
              ნომერი #56546546456
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessContent;
