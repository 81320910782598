const CardStyleInputs = ({
  img,
  title,
  Icon,
  descriptionText,
  Fields,
  needBanner = true,
}) => {
  return (
    <div>
      {needBanner ? (
        <div className="w-full relative mb-10">
          <img src={img} alt="#" className="w-full h-[200px]"></img>
          <Icon
            className="absolute top-[50%] left-[50%] text-white 
        translate-x-[-50%] translate-y-[-50%] text-[100px] max-sm:text-[50px]"
          />
        </div>
      ) : (
        <></>
      )}
      <div className="py-10 px-10 shadow-primary h-full max-md:p-5 rounded-lg">
        <div>
          <h1 className="text-2xl text-center font-semibold font-semibold">
            {title}
          </h1>
          <p className="text-center text-gray-400 mt-2 text-md">
            {descriptionText}
          </p>
        </div>
        <Fields />
      </div>
    </div>
  );
};
export default CardStyleInputs;
