import { Link } from "react-router-dom";
import { Facebook, Twitter, Youtube, CCircle } from "react-bootstrap-icons";
import logoimg from "../imgs/logo.png";
import visaimg from "../imgs/visa.png";

function Footer() {
  return (
    <>
    <div className="out border-t-[1px] border-[#cfcfcf]">
      <section className="footer">
        <div className="main-footer ">
          <div className="history footer-div">
            <div className="bmstore-logo-footer">
              <Link to="/">
                <img src={logoimg} alt=""></img>
              </Link>
            </div>
            <p className="text-history">
              BMSTORE 2022 წლიდან ოპერირებს საქართველოში ტექნიკის ბაზარზე. ჩვენ
              მომხმარებელს მსოფლიო ბრენდების პროდუქციას საუკეთესო ფასად
              ვთავაზობთ საქართველოში
            </p>
            <div className="footer-icons">
              <a href="https://www.facebook.com/">
                <Facebook className="i fb-bg" />
              </a>
              <a href="https://www.facebook.com/">
                <Twitter className="i twiter-bg" />
              </a>
              <a href="https://twitter.com/">
                <Youtube className="i yt-bg" />
              </a>
            </div>
          </div>
          <div className="navigation footer-div">
            <h2 className="na-title">ნავიგაცია</h2>
            <div className="border"></div>
            <ul>
              <li className="main">მთავარი</li>
              <li className="popular">პოპულარული</li>
              <li className="mobile">მობილური</li>
              <li className="technic">ტექნიკა</li>
              <li className="aveji">ავეჯი</li>
            </ul>
          </div>
          <div className="conf footer-div">
            <h2 className="co-title">კონფიდენციალურობა</h2>
            <div className="border"></div>
            <ul>
              <li className="one">საიტის გამოყენება</li>
              <li className="two">საიტის წესები და პირობები</li>
              <li className="three">კონფიდენციალურობის პოლიტიკა</li>
              <li className="four">იურიდიული ინფორმაცია</li>
              <li className="five">ნივთის შეცვლის პოლიტიკა</li>
            </ul>
          </div>
          <div className="payment footer-div">
            <h2 className="pay-title">გადახდები</h2>
            <div className="border"></div>
            <div className="pay-img">
              <img src={visaimg} alt=""></img>
            </div>
          </div>
        </div>
      </section>
      
    </div>
    <div className="out border-t-[1px] border-[#cfcfcf]">
<div className="fotter-bottom w-[100%] max-w-[1500px]">
    <div className="left-fotter">
      <CCircle className="i" />
      <p className="left-footer-txt">ყველა უფლება დაცულია 2022</p>
    </div>
    <p className="right-footer">
      Created by{" "}

      <a href="#"><span style={{ color: "#0066ff" }}>Artspace digital</span></a> Supported
      <a href="#"><span style={{ color: "#0066ff" }}> By Bmsoft</span></a>
    </p>
  </div>
    </div>
    
  </>
  );
}

export default Footer;
