import bg from "../../../imgs/sky.jpg";
import { Link } from "react-router-dom";

const BlogsCard = ({
  imgUrl = bg,
  text = "ბოლოს დამატებული ბლოგი",
  redirectLink = "/",
}) => {
  return (
    <div className="w-[300px] max-sm:w-full">
      <Link to={redirectLink}>
        <img src={imgUrl} className="w-full mb-3 rounded-lg" alt="#"></img>
        <p className="w-full text-center">{text}</p>
      </Link>
    </div>
  );
};

export default BlogsCard;
