import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css/effect-fade";
import { ChevronCompactRight, ChevronCompactLeft } from "react-bootstrap-icons";
import one23img from "../../../imgs/123.png";
import laptopimg from "../../../imgs/laptop.png";
import mtversasrutiimg from "../../../imgs/mtversasruti.png";

function SimpleSlider() {
  let swiperRef = useRef();
  return (
    <div className="arrowsUpSlider">
      <button className="leftarr" onClick={() => swiperRef.current?.slideNext()}>
      <ChevronCompactLeft className="larr"/>
      </button>
      <button className="rightarr" onClick={() => swiperRef.current?.slidePrev()}>
      <ChevronCompactRight className="rarr"/>
      </button>
      
      <Swiper
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        pagination={{ clickable: false }}
        dots={"false"}
        loop={true}
        className="mySwiper"
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        disabledclass={"disabled_swiper_button"}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        effect="fade"
        speed={2000}
        cssease={"linear"}
      >
        <SwiperSlide>
          <div className="sl one">
            <div className="left-side-slider">
              <h1 className="slider-title-one">მიიღე მეტი შესაძლებლობები</h1>
              <div className="see-all">
                <p className="slider-btn-one">მეტის ნახვა</p>
              </div>
            </div>
            <div className="slider-img">
              <img src={one23img} alt=""></img>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="sl two">
            <div className="left-side-slider">
              <h1 className="slider-title-one">მიიღე მეტი შესაძლებლობები</h1>
              <div className="see-all">
                <p className="slider-btn-one">მეტის ნახვა</p>
              </div>
            </div>
            <div className="slider-img">
              <img src={laptopimg} alt=""></img>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="sl three">
            <div className="left-side-slider">
              <h1 className="slider-title-one">მიიღე მეტი შესაძლებლობები</h1>
              <div className="see-all">
                <p className="slider-btn-one">მეტის ნახვა</p>
              </div>
            </div>
            <div className="slider-img">
              <img src={mtversasrutiimg} alt=""></img>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default SimpleSlider;
