import logo from "../../../imgs/logo.png";
import bg from "../../../imgs/sky.jpg";

const AboutText = ({ title, text }) => {
  return (
    <div className="mb-10">
      <h1 className="text-xl font-bold">{title}</h1>
      <p className="text-md text-gray-500">{text}</p>
    </div>
  );
};

const AboutUsContent = () => {
  return (
    <div className="out">
      <div
        className="flex gap-20 max-w-[1500px] w-full max-lg:px-[45px] max-sm:px-[20px]
     px-[110px] max-xl:flex-wrap margin-bottom-primary margin-top-primary"
      >
        <div className="w-[37%] flex max-xl:w-full max-xl:flex  max-xl:justify-center">
          <div className="relative w-[100%] h-[400px] max-xl:w-[70%] max-md:w-full
          max-md:w-[250px] max-md:h-[300px] max-sm:w-[100%]" >
            <img
              src={bg}
              alt="#"
              className="object-cover absolute top-0 left-0 z-20 w-full h-full rounded-tr-2xl  rounded-bl-2xl "
            ></img>
            <img
              src={logo}
              alt="#"
              className="absolute top-[50%] left-[50%] z-30 translate-x-[-50%] translate-y-[-50%]"
            ></img>
            <div
              className="w-28 h-28 bg-[#0e6dff] z-10
            absolute bottom-[-20px] right-[-20px] max-md:bottom-[-10px] max-md:right-[-10px]
        "
            ></div>
          </div>
        </div>
        <div className="w-[63%] max-xl:w-full">
          <AboutText
            title={"სათაური ნომერი პირველი"}
            text={`Karabiner 98 kurz („კარაბინი 98 მოკლე“) — გერმანული მაუზერის შაშხანა ხელით
            გადასატენი გრძივმოძრავი საკეტით, რომელიც იყენებს 7.92×57მმ მაუზერის ვაზნებს. გვხვდება
            სხვადასხვა აბრევიატურებით, როგორიცაა: Karabiner 98k, Kar98k ან K98k. 1935 წლის 
            21 ივნისს ვერმახტმა ის შეიარაღებაში მიიღო. მიუხედავად მოგვიანებით ნახევრად და
            სრულად ავტომატური შაშხანების გამოშვებისა მეორე მსოფლიო ომის პერიოდში, Karabiner
            98k მაინც დარჩა ყველაზე გამოყენებად და გავრცელებულ იარაღად გერმანელ ჯარისკაცებს შორის 
            1945 წლამდე, ომის დასრულებამდე. მეორე მსოფლიო ომის დასრულებისას ამ შაშხანის მილიონობით
            ეგზემპლარი საბჭოელებმა ჩაიგდეს ხელში და შემდგომ, ცივი ომის პერიოდში, მრავალ პროსაბჭოთა 
            ქვეყანას გაუგზავნეს ის სამხედრო დახმარების სახით. Karabiner 98k დღესაც განაგრძობს სხვადასხვა
            შეიარაღებულ კონფლიქტში გამოჩენას.`}
          />
          <AboutText
            title={"სათაური ნომერი მეორე"}
            text={`Karabiner 98 kurz („კარაბინი 98 მოკლე“) — გერმანული მაუზერის შაშხანა ხელით
            გადასატენი გრძივმოძრავი საკეტით, რომელიც იყენებს 7.92×57მმ მაუზერის ვაზნებს. გვხვდება
            სხვადასხვა აბრევიატურებით, როგორიცაა: Karabiner 98k, Kar98k ან K98k. 1935 წლის 
            21 ივნისს ვერმახტმა ის შეიარაღებაში მიიღო. მიუხედავად მოგვიანებით ნახევრად და
            სრულად ავტომატური შაშხანების გამოშვებისა მეორე მსოფლიო ომის პერიოდში, Karabiner
            98k მაინც დარჩა ყველაზე გამოყენებად და გავრცელებულ იარაღად გერმანელ ჯარისკაცებს შორის 
            1945 წლამდე, ომის დასრულებამდე. მეორე მსოფლიო ომის დასრულებისას ამ შაშხანის მილიონობით
            ეგზემპლარი საბჭოელებმა ჩაიგდეს ხელში და შემდგომ, ცივი ომის პერიოდში, მრავალ პროსაბჭოთა 
            ქვეყანას გაუგზავნეს ის სამხედრო დახმარების სახით. Karabiner 98k დღესაც განაგრძობს სხვადასხვა
            შეიარაღებულ კონფლიქტში გამოჩენას.`}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUsContent;
