import ProductInfoStyleTwo from "../../content/productpage/productInfoStyleTwo";
import { useState, useEffect } from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";

const DescripTionTypeOne = ({ descriptionName, descriptionText }) => {
  return (
    <div className="each-habit">
      <p className="name">{descriptionName}:</p>
      <p className="descrip">{descriptionText}</p>
    </div>
  );
};

function ProdBigInformation() {
  const [isOpen, setIsOpen] = useState(false);
  const [showDesc, setShowDesk] = useState(false);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowSize[0] >= 1000) {
      setIsOpen(true);
    } else if (windowSize[0] <= 1000) {
      setIsOpen(false);
    }
  }, [windowSize]);
  return (
    <div className="out">
      <div className="big-info margin-bottom-primary">
        <div className="prod-desc-side">
          <div
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            className="product-description-list-icon"
          >
            <p>დეტალურად</p>
            {isOpen ? (
              <ChevronDown className="i" />
            ) : (
              <ChevronUp className="i" />
            )}
          </div>
          {isOpen ? (
            <div
              className={`info-menu  ${
                isOpen ? "fade-in-effect" : "fade-out-effect"
              }`}
            >
              <div className="f habits">
                <div className="space"></div>
                <i className="bi bi-sliders"></i>
                <p className="hab-title">მახასიათებლები</p>
              </div>
              <div className="f description">
                <div className="space"></div>
                <i className="bi bi-sliders"></i>
                <p className="des-title">პროდუქტის აღწერა</p>
              </div>
              <div className="f rate">
                <div className="space"></div>
                <i className="bi bi-sliders"></i>
                <p className="rate-title">შეფასება</p>
              </div>
              <div className="f video">
                <div className="space"></div>
                <i className="bi bi-sliders"></i>
                <p className="video-title">ვიდეო განხილვა</p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className="prod-information"
          style={showDesc ? {} : { padding: 0, border: 0 }}
        >
          {data.map((item, value) => {
            return (
              <div key={value}>
                {showDesc ? (
                  <>
                    <DescripTionTypeOne
                      descriptionName={item.descriptionName}
                      descriptionText={item.descriptionText}
                    />
                  </>
                ) : (
                  <>
                    <ProductInfoStyleTwo
                      descriptionName={item.descriptionName}
                      descriptionText={item.descriptionText}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProdBigInformation;

const data = [
  {
    descriptionName: "ბრენდი",
    descriptionText: "Apple",
  },
  {
    descriptionName: "დეტალური აღწერა",
    descriptionText: ` "MaGiC გთავაზობთ Iphone-ებს. ტელეფონები არის ამერიკიდან ჩამოტანილი,
          მოყვება დამტენი ბლოკი და უსბ კაბელი, ასევე 6 თვიანი მაღაზიის
          გარანტია. გვაქვს საქართველო ბანკის განვადება ფასები განისაზღვრება
          (მდგომარეობის მიხედვით)`,
  },
  {
    descriptionName: "მოდელი",
    descriptionText: "IPHONE 13 PRO MAX SINGLE SIM 128GB ALPINE GREEN",
  },
  {
    descriptionName: "ტექნოლოგია",
    descriptionText: "GSM/CDMA/HSPA/EVDO/5G",
  },
  {
    descriptionName: "კავშირის სტანდარტი",
    descriptionText: "5G",
  },
  {
    descriptionName: "კორპუსის აწყობა",
    descriptionText: "Front/Back Glass, aluminium frame",
  },
  {
    descriptionName: "სიმ ბარათი-SIM",
    descriptionText: "Single SIM",
  },
  {
    descriptionName: "სიმ ბარათი-SIM ტიპი",
    descriptionText: "Nano-SIM, Dual stand-by",
  },
  {
    descriptionName: "კორპუსის დაცვა",
    descriptionText: "IP68 dust/water resistant (up to 6m for 30mins)",
  },
];
