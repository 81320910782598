const YourOrder = ({ transportvalue = 0, sumValue = 0 }) => {
  return (
    <div className="bg-gray-100 rounded-lg w-full p-5">
      <div className="pb-3">
        <h2 className="text-xl text-bold pb-3 border-b border-gray-300">
          შეკვეთის გაფორმება
        </h2>
      </div>
      <div className="pb-3 border-b border-gray-300">
        <div className="w-full flex justify-between items-center">
          <div>
            <p className="text-md">ტრანსპორტირება</p>
          </div>
          <div>
            <p className="text-md">{transportvalue} ₾</p>
          </div>
        </div>
        <div className="w-full flex justify-between items-center mt-3">
          <div>
            <p className="text-md font-bold">ტრანსპორტირება</p>
          </div>
          <div>
            <p className="text-md">
              <span className="font-bold">ჯამი: </span>
              <span className="font-bold text-green-400">{sumValue} ₾</span>
            </p>
          </div>
        </div>
      </div>
      <div className="pb-3 pt-3">
        <p className="text-md mb-2">პრომო კოდი</p>
        <div className="relative">
          <input
            type="text"
            className="h-10 rounded-full w-full pl-4 focus:outline-none  text-white"
            placeholder="კოდი..."
          ></input>
          <button
            className="bg-[#0e6dff] px-4 py-2 rounded-full
             text-white absolute top-0 right-0"
          >
            გაგზავნა
          </button>
        </div>
      </div>
    </div>
  );
};

export default YourOrder;
