
import PagePath from "../pathOfPages";
import BlogsContentLayout from "../content/blogsComponents/blogsContentLeyout";


function BlogsPage(){
    return(
        <>
        <PagePath/> 
        <BlogsContentLayout/>
        </>
    )
}

export default BlogsPage