import Mouseimg from "../../../imgs/mouse.png";

function CategoriesCont() {
  return (
    <div className="out">
      <div className="cat-cont">
        <div className="cat-cont-up">
          <div className="cat-cont-up-left">
            <p className="title">მაუსები</p>
            <div className="image">
              <img src={Mouseimg} alt="" />
            </div>
          </div>
          <p className="cat-cont-up-right">
            აქ მაუსების დიდი არჩევანია, შეიძინეთ ყველანაირი PC, ბრენდ
            კომპიუტერები, სამუშაო სადგურები, All In One კომპიუტერები, მინი PC,
            თხელი კლიენტები, კონსოლები & აქსესუარები და ლიცენზიები ჩვენთან.
          </p>
        </div>
        <div className="borderi"></div>
        <div className="cat-cont-down">
          <p className="qve-categories">ქვე კატეგორიები</p>
          <div className="products">
            <div className="prod-frst">
                <div className="one-product">
              <div className="prod-img">
                <img src={Mouseimg} alt=""></img>
              </div>
              <p className="mous">მაუსები</p>
            </div>
            <div className="one-product">
              <div className="prod-img">
                <img src={Mouseimg} alt=""></img>
              </div>
              <p className="mous">მაუსები</p>
            </div>
            <div className="one-product">
              <div className="prod-img">
                <img src={Mouseimg} alt=""></img>
              </div>
              <p className="mous">მაუსები</p>
            </div>
            <div className="one-product">
              <div className="prod-img">
                <img src={Mouseimg} alt=""></img>
              </div>
              <p className="mous">მაუსები</p>
            </div>
            </div>
            <div className="prod-sec">
                <div className="one-product">
              <div className="prod-img">
                <img src={Mouseimg} alt=""></img>
              </div>
              <p className="mous">მაუსები</p>
            </div>
            <div className="one-product">
              <div className="prod-img">
                <img src={Mouseimg} alt=""></img>
              </div>
              <p className="mous">მაუსები</p>
            </div>
            <div className="one-product">
              <div className="prod-img">
                <img src={Mouseimg} alt=""></img>
              </div>
              <p className="mous">მაუსები</p>
            </div>
            <div className="one-product">
              <div className="prod-img">
                <img src={Mouseimg} alt=""></img>
              </div>
              <p className="mous">მაუსები</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoriesCont;
