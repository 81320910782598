import TimSlider from "./timCards";
import SliderSlideComponent from "../sliderComponent/SliderComponent";

function SecOneLineAdvertsPart() {
  return (
    <div className="out">
      <section className="sec-one-line-adverts margin-bottom-primary">
        <div className="one-line-header">
          <h1 className="title">ტელეფონები</h1>
        </div>
        <div className="border">
          <div className="oneborder"></div>
          <div className="twoborder"></div>
        </div>

        <SliderSlideComponent
          SliderSlideComponent={TimSlider}
          arrowStyleTwo={true}
        />

      </section>
    </div>
  );
}

export default SecOneLineAdvertsPart;
