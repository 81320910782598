import SliderSlideComponent from "../sliderComponent/SliderComponent";
import TimSlider from "../mainpage/timCards";

function ProdSlider({ myDelay }) {
  return (
    <div className="out">
      <section className="one-line-adverts margin-bottom-primary">
        <div className="one-line-header">
          <h1 className="title">ტელევიზორები</h1>
        </div>
        <div className="border">
          <div className="oneborder"></div>
          <div className="twoborder"></div>
        </div>

        <SliderSlideComponent
            sliderSlideData={dummyData}
            SliderSlideComponent={TimSlider}
            myDelay={myDelay}
            showMore={false}
            arrowStyleTwo={true}
          />
        
      </section>
    </div>
  );
}

export default ProdSlider;

const dummyData = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },

  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
];
