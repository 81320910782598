import { Gear } from "react-bootstrap-icons";

const SinglePriorityCard = ({ Icon, text }) => {
  return (
    <div
      className="w-[23%] max-md:w-[48%] p-5 rounded-xl inline-block shadow max-sm:w-full
        flex flex-col gap-6 items-center justify-center hover:bg-[#0e6dff] group"
    >
      <Icon className="text-[25px] text-[#0e6dff] group-hover:text-white" />
      <h2 className="text-xl font-bold group-hover:text-white">{text}</h2>
    </div>
  );
};

const PriorityLayout = () => {
  return (
    <div className="out">
      <div
        className="max-w-[1500px] w-full max-lg:px-[45px] max-sm:px-[20px]
      px-[110px] max-md:pt-0 max-sm:pt-0 pt-0 margin-bottom-primary">
        <h1 className="text-2xl font-bold w-full text-center mb-6">
          პრიორიტეტები
        </h1>
        <div className="flex flex-wrap justify-between">
          <SinglePriorityCard Icon={Gear} text={"პრიორიტეტი"} />
          <SinglePriorityCard Icon={Gear} text={"პრიორიტეტი"} />
          <SinglePriorityCard Icon={Gear} text={"პრიორიტეტი"} />
          <SinglePriorityCard Icon={Gear} text={"პრიორიტეტი"} />
        </div>
      </div>
    </div>
  );
};

export default PriorityLayout;
