import iphonimg from "../../../imgs/iphon.png";

function ProductCategori() {
  return (
    <div className="products ProductCategoriProduct mobile">
      <div className="s">
        <div className="prod-img">
          <img src={iphonimg} alt=""></img>
        </div>
        <p className="mob">მობილური</p>
      </div>
    </div>
  );
}
 export default ProductCategori