import { useState } from "react";
import Accordion from "./accardion";

const FaqsPage = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <div className="out">
      <div className="faqs-page margin-top-primary margin-bottom-primary">
        <div className="faqs-page-header">
          <h1>ხშირად დასმული შეკითხვები</h1>
        </div>
        <div>
          {data.map((item, index) => {
            return (
              <div
                onClick={() => {
                  toggle(index);
                }}
                key={index}
              >
                <Accordion
                  question={item.question}
                  answer={item.answer}
                  selected={selected}
                  index={index}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const data = [
  {
    question: "კითხვა ?",
    answer: "პასუხი პასუხი პასუხი",
  },
  {
    question: "კითხვა ?",
    answer: "პასუხი პასუხი პასუხი",
  },
  {
    question: "კითხვა ?",
    answer: "პასუხი პასუხი პასუხი",
  },
  {
    question: "კითხვა ?",
    answer: "პასუხი პასუხი პასუხი",
  },
  {
    question: "კითხვა ?",
    answer: "პასუხი პასუხი პასუხი",
  },
  {
    question: "კითხვა ?",
    answer: "პასუხი პასუხი პასუხი",
  },
];

export default FaqsPage;
